.is-sr-only {
  border: none !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.01em !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.01em !important;
}

.is-borderless {
  border: 0px solid !important;
}

.fx-drop-shadow {
  box-shadow: 0 1px 4px $primary, 0 0 40px $dark inset;
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px;
  bottom: 10px;
  z-index: 0;
}

.fx-drop-shadow:before,
.fx-drop-shadow:after {
  content: '';
  position: absolute;
  z-index: -1;
  box-shadow: 0 10px 10px transparentize($dark, 0.5);
  top: 50%;
  bottom: -3px;
  left: 0;
  right: 0;
  border-radius: 100px / 10px;
}

.has-background-grey-highlight {
  background-color: $grey-panel-highlight !important;
}

.is-fixed-bottom {
  position: fixed;
  bottom: 0;
}

.has-background-gradient-neon {
  background: linear-gradient(to right, $primary, $free);
}

.has-background-gradient-primary-dark {
  background: linear-gradient(to bottom, $primary, $brand-dark);
}

.has-background-gradient-dark-primary {
  background: linear-gradient(to bottom, $brand-dark, $primary);
}

.has-background-gradient-to-primary-dark {
  background: linear-gradient(to bottom, $primary, $brand-dark);
}

.has-background-gradient-to-right-primary-dark {
  background: linear-gradient(to right, $primary, $brand-dark);
}

.has-background-gradient-to-right-space-dark-blue {
  background: linear-gradient(to right, #230943, #191f48);
}

.has-background-primary {
  &.modal-background {
    opacity: 0.9 !important;
  }
}

.has-background-transparent {
  background-color: transparent !important;
}

.has-background-blue-light {
  background-color: $blue-light;

  &::placeholder {
    color: $primary;
  }
}

.has-background-dark-grey {
  background-color: $brand-grey !important;
}

.has-background-dark-greyer {
  background-color: $brand-dark-greyer !important;
}

.has-background-light-grey {
  background-color: $grey-light !important;
}

.has-text-light-grey {
  color: $content-text-color !important;
}

.has-text-gold {
  color: $gold;
}

.has-text-shadow-white {
  text-shadow: 1px 1px 0 white !important;
}

.has-border-light {
  border: 1px solid $light;
}

.has-border-dark {
  border: 1px solid $dark;
}

.has-border-primary {
  border: 1px solid $primary;
}

.has-text-underline {
  text-decoration: underline;
}

.has-link-underline {
  a {
    text-decoration: underline;
  }
}

.has-border-radius-8 {
  border-radius: 8px;
}

.is-viewport-height {
  min-height: 100vh;
}

.is-position-fixed-bottom {
  position: fixed;
  bottom: 10px;
}

.m-double-date-range-picker > .column {
  @include until($tablet) {
    margin-bottom: $gap * 2;
  }
}

.m-margin-bottom-gap {
  margin-bottom: $gap;
}

.social-image {
  max-height: 112px;
}

.middle-loader {
  height: 100%;
  width: 100%;
  text-align: center;
}

.page-loader {
  width: 260px;
  text-align: center;
}

.placeholder-image {
  height: 300px;
  background-color: $brand-dark;
}

.divTable {
  display: table;
  width: 100%;
  //border:1px solid  #666666;
  border-spacing: 5px;
}

.divRow {
  display: table-row;
  background-color: #2a2a2a;
  width: auto;
  clear: both;
}

.divCell {
  color: white;
  float: left;
  text-align: center;
  display: table-column;
  width: 20%;
  empty-cells: show;
  box-sizing: border-box;
}

.divHeader {
  background-color: black !important;
}

.divHeader .divCell {
  color: #ffdd19 !important;
}

.divCell img {
  height: 30px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.white-space-unset {
  white-space: unset !important;
}

.m-margin-bottom-0 {
  margin-bottom: 0 !important;
}
.m-margin-top-gap-x2 {
  margin-top: $gap * 2 !important;
}

.img-max-width-300 {
  max-width: 300px;
}

.loader-wrapper {
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;
  background: white;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 60px;
    width: 60px;
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}

.is-full-width {
  width: 100%;
}

.m-promo-widget-title-hero {
  font-size: 1em !important;
  line-height: 1.3 !important;
  margin: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 500 !important;
}

.is-loading-border {
  border: 2px solid white;
}

.has-background-gradient-angled-space-dark-blue {
  background: linear-gradient(45deg, #170b25 25%, #212172);
}

.levels-background-color {
  background-color: #170b25 !important;
}

.color-white {
  color: white !important;
}

.color-primary {
  color: $primary !important;
}

.has-background-color-linear {
  background: linear-gradient(to right, #552c88, #00a9b4);
}

.tournaments-subtitle {
  margin: 0;
  align-items: center;
  color: $white;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.m-margin-top-gap {
  margin-top: $gap;
}

.m-page-wrapper {
  background: transparent !important;
}

@media screen and (max-width: 560px) {
  .m-responsive-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    /* Then style the iframe to fit in the container div with full height and width */
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1267px) {
   .columnSize {
     min-width: 25%;
   }
 }

 .half-padding-right {
   padding-right: 0px;
   @include from($desktop) {
    padding-right: 5px !important;
   }
 }

.bulma-loader-mixin {
  @include loader;
}

.carousel .slider {
  width: 300px !important;
}

.is-clickable {
  cursor: pointer !important;
}


.m-0 {margin: 0}
.m-1 {margin: 0.25rem}
.m-2 {margin: 0.5rem}
.m-3 {margin: 1rem}
.m-4 {margin: 1.5rem}
.m-5 {margin: 2rem}
.m-6 {margin: 3rem}
.m-7 {margin: 3.5rem}
.m-8 {margin: 4rem}
.m-9 {margin: 4.5rem}
.m-10 {margin: 5rem}
.m-11 {margin: 5.5rem}
.m-12 {margin: 6rem}
.m-13 {margin: 6.5rem}
.m-14 {margin: 7rem}
.m-15 {margin: 7.5rem}
.m-16 {margin: 8rem}
.m-17 {margin: 8.5rem}
.m-18 {margin: 9rem}
.m-19 {margin: 9.5rem}
.m-20 {margin: 10rem}

.mt-0 {margin-top: 0}
.mt-1 {margin-top: 0.25rem}
.mt-2 {margin-top: 0.5rem}
.mt-3 {margin-top: 1rem}
.mt-4 {margin-top: 1.5rem}
.mt-5 {margin-top: 2rem}
.mt-6 {margin-top: 3rem}
.mt-7 {margin-top: 3.5rem}
.mt-8 {margin-top: 4rem}
.mt-9 {margin-top: 4.5rem}
.mt-10 {margin-top: 5rem}
.mt-11 {margin-top: 5.5rem}
.mt-12 {margin-top: 6rem}
.mt-13 {margin-top: 6.5rem}
.mt-14 {margin-top: 7rem}
.mt-15 {margin-top: 7.5rem}
.mt-16 {margin-top: 8rem}
.mt-17 {margin-top: 8.5rem}
.mt-18 {margin-top: 9rem}
.mt-19 {margin-top: 9.5rem}
.mt-20 {margin-top: 10rem}

.mb-0 {margin-bottom: 0}
.mb-1 {margin-bottom: 0.25rem}
.mb-2 {margin-bottom: 0.5rem}
.mb-3 {margin-bottom: 1rem}
.mb-4 {margin-bottom: 1.5rem}
.mb-5 {margin-bottom: 2rem}
.mb-6 {margin-bottom: 3rem}
.mb-7 {margin-bottom: 3.5rem}
.mb-8 {margin-bottom: 4rem}
.mb-9 {margin-bottom: 4.5rem}
.mb-10 {margin-bottom: 5rem}
.mb-11 {margin-bottom: 5.5rem}
.mb-12 {margin-bottom: 6rem}
.mb-13 {margin-bottom: 6.5rem}
.mb-14 {margin-bottom: 7rem}
.mb-15 {margin-bottom: 7.5rem}
.mb-16 {margin-bottom: 8rem}
.mb-17 {margin-bottom: 8.5rem}
.mb-18 {margin-bottom: 9rem}
.mb-19 {margin-bottom: 9.5rem}
.mb-20 {margin-bottom: 10rem}

.mr-0 {margin-right: 0}
.mr-1 {margin-right: 0.25rem}
.mr-2 {margin-right: 0.5rem}
.mr-3 {margin-right: 1rem}
.mr-4 {margin-right: 1.5rem}
.mr-5 {margin-right: 2rem}
.mr-6 {margin-right: 3rem}
.mr-7 {margin-right: 3.5rem}
.mr-8 {margin-right: 4rem}
.mr-9 {margin-right: 4.5rem}
.mr-10 {margin-right: 5rem}
.mr-11 {margin-right: 5.5rem}
.mr-12 {margin-right: 6rem}
.mr-13 {margin-right: 6.5rem}
.mr-14 {margin-right: 7rem}
.mr-15 {margin-right: 7.5rem}
.mr-16 {margin-right: 8rem}
.mr-17 {margin-right: 8.5rem}
.mr-18 {margin-right: 9rem}
.mr-19 {margin-right: 9.5rem}
.mr-20 {margin-right: 10rem}

.ml-0 {margin-left: 0}
.ml-1 {margin-left: 0.25rem}
.ml-2 {margin-left: 0.5rem}
.ml-3 {margin-left: 1rem}
.ml-4 {margin-left: 1.5rem}
.ml-5 {margin-left: 2rem}
.ml-6 {margin-left: 3rem}
.ml-7 {margin-left: 3.5rem}
.ml-8 {margin-left: 4rem}
.ml-9 {margin-left: 4.5rem}
.ml-10 {margin-left: 5rem}
.ml-11 {margin-left: 5.5rem}
.ml-12 {margin-left: 6rem}
.ml-13 {margin-left: 6.5rem}
.ml-14 {margin-left: 7rem}
.ml-15 {margin-left: 7.5rem}
.ml-16 {margin-left: 8rem}
.ml-17 {margin-left: 8.5rem}
.ml-18 {margin-left: 9rem}
.ml-19 {margin-left: 9.5rem}
.ml-20 {margin-left: 10rem}

.my-0 {margin-top: 0; margin-bottom: 0}
.my-1 {margin-top: 0.25rem; margin-bottom: 0.25rem}
.my-2 {margin-top: 0.5rem; margin-bottom: 0.5rem}
.my-3 {margin-top: 1rem; margin-bottom: 1rem}
.my-4 {margin-top: 1.5rem; margin-bottom: 1.5rem}
.my-5 {margin-top: 2rem; margin-bottom: 2rem}
.my-6 {margin-top: 3rem; margin-bottom: 3rem}
.my-7 {margin-top: 3.5rem; margin-bottom: 3.5rem}
.my-8 {margin-top: 4rem; margin-bottom: 4rem}
.my-9 {margin-top: 4.5rem; margin-bottom: 4.5rem}
.my-10 {margin-top: 5rem; margin-bottom: 5rem}
.my-11 {margin-top: 5.5rem; margin-bottom: 5.5rem}
.my-12 {margin-top: 6rem; margin-bottom: 6rem}
.my-13 {margin-top: 6.5rem; margin-bottom: 6.5rem}
.my-14 {margin-top: 7rem; margin-bottom: 7rem}
.my-15 {margin-top: 7.5rem; margin-bottom: 7.5rem}
.my-16 {margin-top: 8rem; margin-bottom: 8rem}
.my-17 {margin-top: 8.5rem; margin-bottom: 8.5rem}
.my-18 {margin-top: 9rem; margin-bottom: 9rem}
.my-19 {margin-top: 9.5rem; margin-bottom: 9.5rem}
.my-20 {margin-top: 10rem; margin-bottom: 10rem}

.mx-0 {margin-left: 0; margin-right: 0}
.mx-1 {margin-left: 0.25rem; margin-right: 0.25rem}
.mx-2 {margin-left: 0.5rem; margin-right: 0.5rem}
.mx-3 {margin-left: 1rem; margin-right: 1rem}
.mx-4 {margin-left: 1.5rem; margin-right: 1.5rem}
.mx-5 {margin-left: 2rem; margin-right: 2rem}
.mx-6 {margin-left: 3rem; margin-right: 3rem}
.mx-7 {margin-left: 3.5rem; margin-right: 3.5rem}
.mx-8 {margin-left: 4rem; margin-right: 4rem}
.mx-9 {margin-left: 4.5rem; margin-right: 4.5rem}
.mx-10 {margin-left: 5rem; margin-right: 5rem}
.mx-11 {margin-left: 5.5rem; margin-right: 5.5rem}
.mx-12 {margin-left: 6rem; margin-right: 6rem}
.mx-13 {margin-left: 6.5rem; margin-right: 6.5rem}
.mx-14 {margin-left: 7rem; margin-right: 7rem}
.mx-15 {margin-left: 7.5rem; margin-right: 7.5rem}
.mx-16 {margin-left: 8rem; margin-right: 8rem}
.mx-17 {margin-left: 8.5rem; margin-right: 8.5rem}
.mx-18 {margin-left: 9rem; margin-right: 9rem}
.mx-19 {margin-left: 9.5rem; margin-right: 9.5rem}
.mx-20 {margin-left: 10rem; margin-right: 10rem}

.p-0 {padding: 0}
.p-1 {padding: 0.25rem}
.p-2 {padding: 0.5rem}
.p-3 {padding: 1rem}
.p-4 {padding: 1.5rem}
.p-5 {padding: 2rem}
.p-6 {padding: 3rem}
.p-7 {padding: 3.5rem}
.p-8 {padding: 4rem}
.p-9 {padding: 4.5rem}
.p-10 {padding: 5rem}
.p-11 {padding: 5.5rem}
.p-12 {padding: 6rem}
.p-13 {padding: 6.5rem}
.p-14 {padding: 7rem}
.p-15 {padding: 7.5rem}
.p-16 {padding: 8rem}
.p-17 {padding: 8.5rem}
.p-18 {padding: 9rem}
.p-19 {padding: 9.5rem}
.p-20 {padding: 10rem}

.pt-0 {padding-top: 0}
.pt-1 {padding-top: 0.25rem}
.pt-2 {padding-top: 0.5rem}
.pt-3 {padding-top: 1rem}
.pt-4 {padding-top: 1.5rem}
.pt-5 {padding-top: 2rem}
.pt-6 {padding-top: 3rem}
.pt-7 {padding-top: 3.5rem}
.pt-8 {padding-top: 4rem}
.pt-9 {padding-top: 4.5rem}
.pt-10 {padding-top: 5rem}
.pt-11 {padding-top: 5.5rem}
.pt-12 {padding-top: 6rem}
.pt-13 {padding-top: 6.5rem}
.pt-14 {padding-top: 7rem}
.pt-15 {padding-top: 7.5rem}
.pt-16 {padding-top: 8rem}
.pt-17 {padding-top: 8.5rem}
.pt-18 {padding-top: 9rem}
.pt-19 {padding-top: 9.5rem}
.pt-20 {padding-top: 10rem}

.pb-0 {padding-bottom: 0 !important}
.pb-1 {padding-bottom: 0.25rem !important}
.pb-2 {padding-bottom: 0.5rem !important}
.pb-3 {padding-bottom: 1rem !important}
.pb-4 {padding-bottom: 1.5rem !important}
.pb-5 {padding-bottom: 2rem !important}
.pb-6 {padding-bottom: 3rem !important}
.pb-7 {padding-bottom: 3.5rem !important}
.pb-8 {padding-bottom: 4rem !important}
.pb-9 {padding-bottom: 4.5rem !important}
.pb-10 {padding-bottom: 5rem !important}
.pb-11 {padding-bottom: 5.5rem !important}
.pb-12 {padding-bottom: 6rem !important}
.pb-13 {padding-bottom: 6.5rem !important}
.pb-14 {padding-bottom: 7rem !important}
.pb-15 {padding-bottom: 7.5rem !important}
.pb-16 {padding-bottom: 8rem !important}
.pb-17 {padding-bottom: 8.5rem !important}
.pb-18 {padding-bottom: 9rem !important}
.pb-19 {padding-bottom: 9.5rem !important}
.pb-20 {padding-bottom: 10rem !important}

.pr-0 {padding-right: 0}
.pr-1 {padding-right: 0.25rem}
.pr-2 {padding-right: 0.5rem}
.pr-3 {padding-right: 1rem}
.pr-4 {padding-right: 1.5rem}
.pr-5 {padding-right: 2rem}
.pr-6 {padding-right: 3rem}
.pr-7 {padding-right: 3.5rem}
.pr-8 {padding-right: 4rem}
.pr-9 {padding-right: 4.5rem}
.pr-10 {padding-right: 5rem}
.pr-11 {padding-right: 5.5rem}
.pr-12 {padding-right: 6rem}
.pr-13 {padding-right: 6.5rem}
.pr-14 {padding-right: 7rem}
.pr-15 {padding-right: 7.5rem}
.pr-16 {padding-right: 8rem}
.pr-17 {padding-right: 8.5rem}
.pr-18 {padding-right: 9rem}
.pr-19 {padding-right: 9.5rem}
.pr-20 {padding-right: 10rem}

.pl-0 {padding-left: 0}
.pl-1 {padding-left: 0.25rem}
.pl-2 {padding-left: 0.5rem}
.pl-3 {padding-left: 1rem}
.pl-4 {padding-left: 1.5rem}
.pl-5 {padding-left: 2rem}
.pl-6 {padding-left: 3rem}
.pl-7 {padding-left: 3.5rem}
.pl-8 {padding-left: 4rem}
.pl-9 {padding-left: 4.5rem}
.pl-10 {padding-left: 5rem}
.pl-11 {padding-left: 5.5rem}
.pl-12 {padding-left: 6rem}
.pl-13 {padding-left: 6.5rem}
.pl-14 {padding-left: 7rem}
.pl-15 {padding-left: 7.5rem}
.pl-16 {padding-left: 8rem}
.pl-17 {padding-left: 8.5rem}
.pl-18 {padding-left: 9rem}
.pl-19 {padding-left: 9.5rem}
.pl-20 {padding-left: 10rem}

.py-0 {padding-top: 0!important; padding-bottom: 0!important}
.py-1 {padding-top: 0.25rem; padding-bottom: 0.25rem}
.py-2 {padding-top: 0.5rem!important; padding-bottom: 0.5rem!important;}
.py-2-5 {padding-top: 0.75rem!important; padding-bottom: 0.75rem!important;}
.py-3 {padding-top: 1rem!important; padding-bottom: 1rem!important;}
.py-4 {padding-top: 1.5rem!important; padding-bottom: 1.5rem!important;}
.py-5 {padding-top: 2rem; padding-bottom: 2rem}
.py-6 {padding-top: 3rem; padding-bottom: 3rem}
.py-7 {padding-top: 3.5rem; padding-bottom: 3.5rem}
.py-8 {padding-top: 4rem; padding-bottom: 4rem}
.py-9 {padding-top: 4.5rem; padding-bottom: 4.5rem}
.py-10 {padding-top: 5rem; padding-bottom: 5rem}
.py-11 {padding-top: 5.5rem; padding-bottom: 5.5rem}
.py-12 {padding-top: 6rem; padding-bottom: 6rem}
.py-13 {padding-top: 6.5rem; padding-bottom: 6.5rem}
.py-14 {padding-top: 7rem; padding-bottom: 7rem}
.py-15 {padding-top: 7.5rem; padding-bottom: 7.5rem}
.py-16 {padding-top: 8rem; padding-bottom: 8rem}
.py-17 {padding-top: 8.5rem; padding-bottom: 8.5rem}
.py-18 {padding-top: 9rem; padding-bottom: 9rem}
.py-19 {padding-top: 9.5rem; padding-bottom: 9.5rem}
.py-20 {padding-top: 10rem; padding-bottom: 10rem}

.px-0 {padding-left: 0!important; padding-right: 0!important}
.px-1 {padding-left: 0.25rem; padding-right: 0.25rem}
.px-2 {padding-left: 0.5rem; padding-right: 0.5rem}
.px-3 {padding-left: 1rem; padding-right: 1rem}
.px-4 {padding-left: 1.5rem!important; padding-right: 1.5rem!important;}
.px-5 {padding-left: 2rem!important; padding-right: 2rem!important;}
.px-6 {padding-left: 3rem; padding-right: 3rem}
.px-7 {padding-left: 3.5rem; padding-right: 3.5rem}
.px-8 {padding-left: 4rem; padding-right: 4rem}
.px-9 {padding-left: 4.5rem; padding-right: 4.5rem}
.px-10 {padding-left: 5rem; padding-right: 5rem}
.px-11 {padding-left: 5.5rem; padding-right: 5.5rem}
.px-12 {padding-left: 6rem; padding-right: 6rem}
.px-13 {padding-left: 6.5rem; padding-right: 6.5rem}
.px-14 {padding-left: 7rem; padding-right: 7rem}
.px-15 {padding-left: 7.5rem; padding-right: 7.5rem}
.px-16 {padding-left: 8rem; padding-right: 8rem}
.px-17 {padding-left: 8.5rem; padding-right: 8.5rem}
.px-18 {padding-left: 9rem; padding-right: 9rem}
.px-19 {padding-left: 9.5rem; padding-right: 9.5rem}
.px-20 {padding-left: 10rem; padding-right: 10rem}