.m-winnerslist-widget {
  background: linear-gradient(#FB2FFB 0%, #292D7D 50%, #4F57F9 89%);
  border-radius: 10px;
  padding: 2px;

  &.m-game-grid-item.column {
    grid-row: 1 / 3;
    max-height: 500px;
  }

  &__container {
    background: black;
    border-radius: 8px;
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__header {
    overflow-x: hidden;
  }

  &__title {
    text-transform: uppercase;
    padding-top: 10px !important;
  }

  &__rankings {
    max-height: 290px;
    height: 100%;

    .carousel-root,
    .carousel-slider,
    .slider-wrapper {
      height: 100% !important;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #000;
    }

    &::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 5px;
      height: 50%;
    }

    &::-webkit-scrollbar-corner {
      background: rgba(0,0,0,0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken(black, 10%);
    }

    &:not(.m-game-grid-item.column) {
      max-height: initial;
    }

    &-item {
      border: 1px solid rgba(34, 44, 139, 1);
      border-radius: 3px;
      display: grid;
      grid-template-columns: 1fr 80px;
      grid-template-rows: 55px;
      align-items: center;
      font-size: .9rem;
      box-sizing: border-box;
      position: relative;

      @include until(500px) {
        grid-template-columns: 1fr 55px;
      }

      .item-container {
        display: grid;
        grid-template-columns:  2fr 1fr;
        position: relative;
      }

      .item-container > div {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .button.play-now {
        position: absolute;
        opacity: 0;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%);
        border: none;
        background: $primary;
        box-sizing:border-box;
        transition: opacity 0.2s ease;

        &:hover {
          -webkit-box-shadow: 1px 3px 27px 0px #534a17;
          box-shadow: 1px 3px 27px 0px #534a17;
        }

        @include from(768px) {
          font-size: 10px;
        }

        @include from(1000px) {
          font-size: 12px;
        }

        @include from(1130px) {
          font-size: 10px;
        }

        @include from(1500px) {
          font-size: 0.8rem;
        }

        @include until(767px) {
          font-size: 14px;
        }

        @include until(600px)  {
            font-size: 12px;
        }

        @include until(420px) {
            font-size: 10px;
        }

        @include until(350px) {
            font-size: 9px;
        }

        i.fa-play {
          padding-left: 0.5rem;
        }
      }

      &-user {
        text-align: left;
        overflow-x: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          scrollbar-width: thin
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: black;
          border-radius: 5px;
          height: 50%;
        }

        &::-webkit-scrollbar-corner {
          background: rgba(0,0,0,0.5);
        }

        &::-webkit-scrollbar-thumb:hover {
          background: darken(black, 10%);
        }
      }

      &-score {
        margin-left: auto;
      }

      &-image {
        figure {
          height: 100%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
          }
        }
      }
    }
  }

  &:not(.m-game-grid-item.column) {
    width: 300px;
    height: 500px;
    position: fixed;
    right: -300px;
    top:50%;
    transform: translateY(-50%);
    z-index: 100;
    transition: right ease-out 500ms;
    transition-timing-function: cubic-bezier(0.27, 0.33, 0.5, 1.5);

    &::before {
      content: url(/images/trophy_white.svg);
      position: absolute;
      top: 50%;
      left: -50px;
      transform: translateY(-50%);
      background: $primary;
      width: 50px;
      height: 50px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 0.5rem;
      z-index: 10;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    &.is-active {
      right: 0;
      transition: all ease-in 500ms;
      transition-timing-function: cubic-bezier(0.27, 0.33, 0.5, 1.5);

      @include until($tablet) {
        left: 50%;
        top: 50%;
        width: 95%;
        transform: translate(-50%, -50%);

        .m-winnerslist-widget__rankings {

          &-item {
            .item-container > div {
              font-size: 1rem !important;
            }
          }
        }
      }

      .button.play-now {
        font-size: 1rem;
      }

      .m-winnerslist-widget__close {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        cursor: pointer;
        width: 40px;
        height: 40px;

        @include until($tablet) {
          top: 1rem;
        }
      }

       // Trophy icon
       &::before {
        left: 0;
        opacity: 0;
        z-index: 0;
      }
    }
  }
}