.m-banking {
  .column {
    img {
      height: 50px;
    }

    .button {
      width: 100%;
      padding: 0;
    }
  }

  .card {
    position: relative;

    &.is-active {
      .card-overlay {
        opacity: 1;
        pointer-events: all;
        z-index: 1;
        transition: opacity ease 300ms;

        .delete {
          pointer-events: all;
        }
      }
    }
  }

  .card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    transition: opacity ease 300ms;
    opacity: 0;
    pointer-events: none;

    .delete {
      position: absolute;
      top: $gap * 2;
      right: $gap * 2;
      pointer-events: none;
    }
  }

  &.table tbody tr:last-child td,
  .table tbody tr:last-child th {
    border-width: 0 0 1px;
  }
}

.m-banking-mobile-head {
  @include until($desktop) {
    height: 56px;
  }
}

.m-banking-table-head {
  background-color: $primary;

  th {
    color: whitesmoke;
    

    @include from(800px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @include until($desktop) {
      color: $dark;
    }
    @include from(800px) {
      color: white;
    }
  }
}

.m-banking-page {
  img {
    @include until($tablet) {
      height: 38px;
    }
    max-height: 38px;
  }
  tr:not(.m-banking-table-head) {
    @include from($tablet) {
      background-color: $light;
      border-bottom: 1px solid white;
    }
  }
}

.m-cashier-controls {
  .button {
      height: auto !important;
      width: auto;
      min-height: 55px;
      margin: 0 0 0 auto;
  }
.m-cashier-balance {
    .card-content {
        @include until($tablet) {
            padding: 10px 0 5px;
        }
    }
}
.level-item {
    padding-right: 0 !important;
    @include from($tablet) {
        flex-direction: column;
        > .level {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include until($tablet) {
        .level-left,
        .level-right {
            display: block;
            margin-bottom: 10px;
        }
        .level {
            margin: 0;
        }
    
    }

    small {
        text-align: center;
    }
   
}

@include until($tablet) {
  > * {
      &:nth-child(1n) {
          order: 2;
      }
      
      &:nth-child(2n) {
          order: 1;
      }
      
      &:nth-child(3n) {
          order: 1;
      }
  }
}
}

.m-cashier-balance-container {
  @include until(500px) {
    width: 100% !important;
  }
}

.m-cashier-button {
  @include until(500px) {
    min-width: 127px;
  }
}

.m-cashier-balance {
  line-height: 0.75;
  &.card {
    padding: 0 5px 5px;
    margin: 0;
  }
  .card-content {
    padding: 10px 4px;
  }
  .level {
    @include until(500px) {
      text-align: left;
    }
  }
  .level-item {
    position: relative;
    padding: 0 20px 0 0;
    .m-tool-tip {
      position: absolute;
      right: 16px;
      top: 18px;
      text-align: center;
    }
    @include from($tablet) {
      padding-left: 10px;
    }
    @include from(500px) {
      width: 80%;
    }
    justify-content: left;
  }
  small {
    font-size: 14px;
    display: block;
    margin-bottom: -5px;
    text-align: center;
    line-height: 1;
    text-transform: capitalize;

    @include until(500px) {
      width: 100%;
      margin: auto auto -5px;
      text-align: left;
      font-size: 12px;

    }
  }
}

.m-cashier-buttons {
  margin-left: -5px;
  margin-right: -5px;
  @include until($tablet) {
    display: block !important;
    margin: 0;
  }
  .button {
    width: 100%;
    margin-bottom: 5px;
    @include from($desktop) {
      width: 50%;
      margin: 5px;
      flex: 0 1 50%;
    }
  }
}


