.select,
.select select {
  width: 100%;

  &:after {
    border-color: #363636 !important;
  }
}

fieldset {
  border: 0px solid;
}

.m-legend {
  padding-bottom: $gap * 2;
  margin-bottom: $gap * 2;
  border-bottom: 1px solid $primary;
  display: block;
  width: 100%;
  color: $content-text-color;
}

.m-fieldset {
  margin-bottom: $gap * 2;
}

.is-checkradio[type='checkbox'] + label::before,
.is-checkradio[type='checkbox'] + label:before,
.is-checkradio[type='checkbox']:checked + label::after,
.is-checkradio[type='checkbox']:checked + label:after {
  margin-top: 5px;
}

.m-field-label + .field-body {
  padding-right: 20px;
}

.header,
.m-game-login-area {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-color: white !important;
    -webkit-box-shadow: 0 0 30px 0 white inset !important;
    -webkit-text-fill-color: $primary !important;
    //font-size: 0.9rem;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $primary;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: $primary;
    opacity: 1;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    color: $primary;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    color: $primary;
  }
}

input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: $content-text-color !important;
  font-family: $family-sans-serif;
}

.input,
.select,
.select select {
  background-color: $white;
  color: $content-text-color;
  &:after {
    border-color: $content-text-color !important;
  }
  option {
    &:disabled {
      color: $content-text-color;
    }
  }
}

select {
  font-family: $family-sans-serif;
}

.textarea {
  color: $content-text-color;
}

.control.has-icons-left .input:focus ~ .icon {
  color: $content-text-color;
}

.label {
  color: $content-text-color;
}

.field-check-box {
  a {
    color: $content-text-color;
    text-decoration: underline;
  }
}

.control.has-icons-left .input[disabled] + .icon {
  color: $brand-dark;
}

.m-alert-message {
  max-width: 100%;
}

.custom-password-field {
  .icon-visibility {
    cursor: pointer;
  }
}

.control {
  &.has-icons-right {
    &.multiple-icons {
      .icon {
        &.icon-visibility {
          right: 30px;
        }
      }

      input {
        padding-right: 4em;
      }
    }
  }
}

.m-field-checkbox-group {
  .m-field-checkbox {
    margin-left: 18px !important;
  }
  p {
    margin-left: 18px;
  }
}

.is-checkradio[type='checkbox']:checked + label::after,
.is-checkradio[type='checkbox']:checked + label:after {
  border-width: 3px;
  width: 0.4rem;
  height: 0.8rem;
  top: 0.3em;
}

.switch[type='checkbox']:focus + label:after,
.switch[type='checkbox']:focus + label::before {
  outline: none;
}

.select:not(.is-multiple):after {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: ' ' !important;
  display: block !important;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.625em;
  right: 12px;
}

.select {
  &.is-medium {
    &:after {
      right: 1.125em;
    }
  }
}
