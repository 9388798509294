@mixin delay($rule, $number, $value) {
  @for $i from 1 to ($number + 1) {
    &:nth-child(#{$i}) {
      -webkit-#{$rule}-delay: (#{$i * $value});
      #{$rule}-delay: (#{$i * $value});
    }
  }
}

.messagesInBox {
  width: 50px;
  height: 50px;
  margin: 0 1rem 0 0;
  position: relative;

  @include until($tablet) {
    position: initial;
    margin-right: 0.5rem;
  }

  &__redDot {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    background-color: rgb(175, 1, 1);
    top: 0;
    right: -3px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &__counter {
      color: white;
      font-size: 11px;
      font-weight: bold;
    }
  }


  &__icon__wrapper {
    position: relative;
  }

  &__icon {
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    overflow: hidden;

    & > img {
      background-size: cover;
      margin-top: 10px;
    }
  }

  &__unread_messages {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3f3a90;
    color: white;
    font-weight: bold;
    width: 400px;
    z-index: 99;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @include until($tablet) {
      width: 100%;
    }

    &.navigation {
      background:none;
      color: $primary;
      justify-content: right;
      padding: 1rem;
      margin-left:auto;
      cursor: pointer;

      span:first-child {
        // content: "\003C";
        font-size: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  &__no-messages {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__dropdown__wrapper {
    position: absolute;
    top: 67px;
    left: -160px;
    width: 400px;
    z-index: 99;

    @include until($tablet) {
      left: 0;
      top: 81px;
      width: 100vw;
      height: fit-content;
      max-height: calc(100vh - 120px);
    }
  }

  &__dropdown {
    max-height: 500px;
    width: 400px;
    overflow-y: auto;
    background: white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius:16px;
    animation: fade 0.3s ease-in-out;

    @include until($tablet) {
      width: 100vw;
      height: fit-content;
      max-height: calc(100vh - 120px);
      border-top-left-radius:0px;
      border-top-right-radius:0px;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }

    &_messages {
      padding: 0;
    }

    &_message {
      opacity: 0;
      animation: toBottom 0.25s forwards;
      @include delay(animation, 20, 0.05s);
      position: relative;
      padding: 0.25rem 1rem 1rem 1.5rem;
      min-height: 75px;
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:not(.open) {
        cursor: pointer;
      }

      &:hover:not(.open) {
        background-color: #efefef;
      }
      &.open {
        padding-bottom: 1.5rem;
      }

/*       &:not(:last-child) {
        border-bottom: 1px solid $primary;
      } */

      &.opened {
        filter: opacity(0.8);
      }

      &-title {
        font-weight: bold;
        margin: 0 0 .5rem;
        &::first-letter {
          text-transform: uppercase;
        }

        &.opened {
          opacity: 0.8;
        }
      }

      h5 {
        margin-bottom: .5rem;
      }

      .messagesInBox__chatBubble,
      .messagesInBox__done-icon {
        position: absolute;
        transform: translateY(-50%);
        top: 22px;
        left: 0.5rem;
        width: 12px;
        height: 12px;
      }

      time {
        font-size: 0.7rem;
        opacity: 0.8;
      }

      .content {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex: 1;

        & .container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100%;
        }

        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        h6 {
          margin-top:0.2rem;
          font-size: 1rem;
        }
      }

      &__buttons {
        display: flex;
        justify-content: right;
        column-gap: .7rem;
        padding-top: .5rem;
        z-index: 9999;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(-10px, -50%);
      }
    }

    .pin__button {
      border: none;
      background-color: transparent;
      height: 25px;
      width: 25px;
      padding: 0;

      img {
        height: 25px;
        width: 25px;
      }
    }
  }

  &__deleteBtn {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;

    & > div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    & > div > span {
      position: absolute;
      background: white;
      display: inline-block;
      height: 15px;
      width: 4px;
      top: 5px;
    }

    & > div > span:first-child {
      transform: rotate(-45deg);
    }

    & > div > span:last-child {
      transform: rotate(45deg);
    }
  }

}

@-webkit-keyframes fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes toBottom {
  0% {
    -webkit-transform: translateY(-180px);
    transform: translateY(-180px);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes toBottom {
  0% {
    -webkit-transform: translateY(-180px);
    transform: translateY(-180px);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
