.m-promo-widget {
    .card-content {
        padding: 0 0 $card-content-padding;
    }
    .level {
        display: flex !important;
        min-width: 0;
        .level-left, .level-right {
            width: auto !important;
            min-width: 0;
        }

        .level-item {
            flex: 1;
            min-width: 0;
        }
    }
    &-item {
        background-color: rgba(255, 255, 255, 0.1);
        margin-bottom: 2px !important;
        transition: background-color ease 100ms;

        &:hover {
            background: rgba(255, 255, 255, 0.05);
            transition: background-color ease 100ms;
        }

       
    }

    &-item-text {
        display: block;
        width: 100%;
        padding: $gap/2 $gap;
    }

    &-title {
        margin: 0 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-weight: 500 !important;
        .content & {
            font-size: 1.4em;
        }

        @include until(1330px) {
            .content & {
                font-size: 1.2em;
                font-size: 1.5vw;
            }
        }

        @include until($desktop) {
            .content & {
                font-size: 1.3em;
            }
        }
        @include until($tablet) {
            .content & {
                font-size: 1em;
            }
        }
    }

 
}


.m-promo-calendar-widget {
    margin: $gap 0;
    height: 100%;
    align-content: flex-start;
    border-radius: 8px;
    
    h5 {
        margin-bottom: 0;
    }
    
    .card-content {
        padding: $gap;
    }

    &-item {
        padding: 0;
        margin-bottom: 2px;
        > .columns {
            .column {
                &:first-child {
                    margin-bottom: 2px !important;
                }
            }
        }

    }

  
    .m-promo-widget-item {
        
        margin-bottom: 2px !important;
        align-items: flex-start;

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        img { vertical-align: top; }

        .level-item {
            min-width: 0;
            flex: 1;
        }
    }

    .m-promo-widget-item-meta {
        margin-bottom: 4px;
    }

    .m-promo-widget-title {
        font-size: 1.3em;

        @include until($desktop) {
            font-size: 1.1em;
        }
    }
   
}

a.m-promo-calendar-month-link {
    background-color: #70A8C9;

    span {
        text-decoration: underline;
    }

    &:hover {
        background-color: white;
        span {
            color: $primary;
        }
    }
}

.m-promo-calendar-month-link {
    width: 100%;
    text-align: center;
    display: block;
    position: relative;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 24px;
    text-transform: uppercase;
    
    span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

  
}

.m-promo-widget-tag {
    position: absolute;
    right: $gap;
    top: -30px;
}

.card-content {
    .m-blog-list & {
        padding: 12px $gap;
    }
}

.m-promo-blog-title-banner {
    .m-title-font {
        span {
            padding-left: 15px;
            padding-right: 15px;
            box-shadow: 10px 0 0 $primary, -10px 0 0 $primary;
            display: inline-block;
            background-color: $primary;
            line-height: 1.4;
        }
    }
}

.m-widget-thumbnail-holder {
  width: 88px;
  height: 62px;
  background-size: auto 100%;
  background-position: center;
}