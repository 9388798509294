// =========================================================================
// Bulma Variables
// =========================================================================

$fa-font-path: "../webfonts";
$family-sans-serif: 'Open Sans', 'Tahoma', 'Trebuchet MS', sans-serif;
$font-title-primary: 'GoBold Reg', sans-serif;
$brand-yellow: #ffdd19;
$brand-dark: #494949;
$brand-grey: #050505;
$brand-mid-grey: #252525;
$brand-dark-grey: #191F20;
$brand-dark-greyer: #2A2A2A;
$scheme-main: white;
$primary: #552c88;
$primary-dark: darken($primary, 10%);
$primary-opaque: rgba(255,221,25,0.9);
$success: #79B400;
$blue: #07a0d9;
$blue-light: #e8f0fd;
$light: #d9d9da;
$grey-light: #cccdd1;
$grey-panel: #E2E2E3;
$grey-panel-highlight: #ECECED;
$dark: $brand-dark;
$dark-green: #239a04;
$dull-green: #186803;
$light-green: #4ef722;
$free: #00a9b4;
$info: $free;
$link: $brand-dark;
$gold: #9A7A63;
$facebook: #39569c;
$twitter: #1DA1F2;
$youtube: #ff0000;
$content-bg-color: #191E21;
$content-text-color: $brand-dark-grey;
$button-blue: #2c5196;
$button-blue-onhover: #1b3566;
$teritary: #9e9e9e;
// =========================================================================
// Breakpoints
// =========================================================================
$fullhd: 1610px;
$tablet: 710px;

// =========================================================================
// Columns
// =========================================================================

$column-gap: 0.2rem;

// =========================================================================
// Layout Rules
// =========================================================================

$container-width: 1630px;
$gap: 10px;


// =========================================================================
// Navbar Variables
// =========================================================================

$navbar-background-color: transparent;
$navbar-item-color: white;
$navbar-item-active-color: white;
$navbar-item-active-background-color: darken($primary, 30%);
$navbar-item-hover-color: $navbar-item-active-color;
$navbar-item-hover-background-color: darken($primary, 30%);
$navbar-breakpoint: 320px;
$navbar-burger-color: $brand-dark;	


// =========================================================================
// Footer Rules
// =========================================================================
$footer-background-color: $brand-dark;
$footer-color: #A09FA0;

// =========================================================================
// Button Variables
// =========================================================================

$button-padding-horizontal: 2rem;
$button-border-width: 2px;

// =========================================================================
// Modal Variables
// =========================================================================

$modal-z: 10000;
$modal-content-width: 800px;
$modal-card-head-radius: 20px;
$modal-card-foot-radius: 20px;
$modal-card-head-padding: 50px;
$modal-card-body-padding: 20px 50px;
$modal-card-head-background-color: white;
$modal-card-foot-background-color: white;
// =========================================================================
// Steps Variables
// =========================================================================

$steps-default-color: $grey-light;
$steps-maker-default-color: $grey-light;
$steps-completed-color: $success;
$steps-active-color: $success;

// =========================================================================
// Accordion Variables
// =========================================================================

//$accordion-radius: $radius !default

$accordion-header-background-color: $light;
$accordion-header-color: $primary;
$accordion-header-padding: 0.75em;
$accordion-header-radius: 8px;
$accordion-content-color: $content-text-color;


// =========================================================================
// Form Variables
// =========================================================================
$label-color: $grey-light;
$label-weight: 500;
$input-disabled-background-color: $grey-panel-highlight;
$input-disabled-border-color: white;
$input-disabled-color: $brand-dark;
$input-color: $grey-light;
$input-focus-border-color: $primary;
$textarea-color: $grey-light;

// =========================================================================
// Table Variables
// =========================================================================
$table-background-color: transparent;
$table-color: $content-text-color;
$table-head-cell-color: $grey-light;
$table-cell-heading-color: $grey-light;
$table-cell-border: $grey-light;
$table-striped-row-even-background-color: $brand-grey;
$table-head-cell-color: $grey-light;

$card-background-color: $brand-dark;
$card-footer-border-top: $brand-grey;

$modal-card-head-background-color: $primary;
$modal-card-foot-background-color: $brand-grey;
$modal-card-foot-border-top: $brand-grey;
$modal-card-head-border-bottom: $brand-grey;

$calendar-today-color: $primary;