.m-game-grid-button {
  height: 36px;
  line-height: 0;
  border-radius: 6px;
}

.button {
  border-radius: 8px;
  font-weight: 600;
  outline: 0;
  text-transform: uppercase;

  transition: all ease 250ms;

  &:hover {
    transition: all ease 250ms;
  }

  &.is-rounded {
    border-radius: 50px;
  }

  &.is-loading::after {
    border-radius: 50%;
    border: 2px solid $success;
    border-right-color: transparent;
    border-top-color: transparent;
  }

  &.is-dark.is-loading::after {
    border-color: transparent transparent $success $success !important;
  }

  &.m-header-sign-up-button {
    .m-header-account-sign-in & {
      background-color: $success;
      color: white;

      &:hover {
        background-color: darken($success, 1);
      }
    }
  }
  &.is-dark-green,
  &.is-success {
    background-color: $dark-green;
    color: white;
    border-color: $dark-green;
    transition: all ease 250ms;

    &:hover {
      transition: all ease 250ms;
      background-color: $dull-green;
      border-color: $dark-green;
    }

    &:disabled {
      background-color: $dark-green;
    }
  }
  &.is-dark-blue {
    background-color: $button-blue;
    color: white;
    border-color: $button-blue;
    transition: all ease 250ms;

    &:hover {
      transition: all ease 250ms;
      background-color: $button-blue-onhover;
      border-color: $button-blue;
    }
  }
  &.is-full-width {
    width: 100%;
  }
  &.is-wide {
    min-width: 350px;
  }
  &.is-wide-desktop {
    @include from($desktop) {
      min-width: 350px;
    }
  }
  &.is-centered {
    margin-left: auto;
    margin-right: auto;
  }
  &.is-maintenance {
    height: auto;
    display: block;
    pointer-events: none !important;
    padding: 5px !important;

    i {
      @include from($tablet) {
        margin-right: 0;
      }
    }
  }
  &.is-xlarge-fullhd {
    @include from($fullhd) {
      border-radius: 100px;
      min-width: 350px;
    }
  }
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.is-tiny-touch {
    @include until($desktop) {
      font-size: 10px !important;
      display: block
    }
    @include until(600px) {
      table & {
        margin-top: -3px;
        margin-bottom: -3px;
      }
    }
   
    &.is-primary {
      &.is-loading {
        color: transparent !important;
        background-color: $primary !important;
        outline: none;

        &::after {
            border-color: $primary !important;
        }
    }

      &:hover {
        background-color: darken($primary, 10%);
      }
    }
  }
  &.is-outline-dark {
    border-color: $dark !important;
    &:hover {
      background-color: darken($primary, 10%);
    }  
  }

  &.is-tournament {
    background-color: #1EF7B0;
    white-space: initial;
    border: 0px solid;
    height: auto;
    font-size: 0.9rem;

    &:hover {
      background-color: darken(#1EF7B0, 10%);
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1.0);
    }
    15% {
      transform: scale(1.1);
    }
    30% {
      transform: scale(1.0);
    }
    50% {
      transform: scale(1.1);
    }
    65% {
      transform: scale(1.0);
    }
  }
}
.react-cookie-banner {
  background-color: $brand-grey;
  .button-close {
    @extend .button;
    @extend .is-success;
    text-transform: uppercase;
  }
}

a.m-button-scroll-to-top,
.m-button-scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background: white;
  color: $primary;
  border: 4px solid $primary;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  z-index: 99;
  i {
    font-size: 30px;
  }
  @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
    bottom: 50px;
  }
}

.step-content {
 .button {
   text-decoration: none !important;
 }
}

.button-disabled-resend {
  pointer-events: none;
  cursor: not-allowed !important;
  text-decoration: none !important;
  opacity: 0.4 !important;
}

.m-bonus-preselector-button {
  border-radius: 100px;
  border-color: white !important;
  background-color: transparent;
  color: white;
  &:hover,
  &.is-disabled {
    background-color: rgba(255,255,255,0.15);
    color: white;
  }
  @include until($tablet) {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    min-height: 45px !important;
    margin-left: 1rem !important;
  }
}
