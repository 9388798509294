.pageloader::after {
  z-index: 1;
  display: none;
}

.pageloader {
  background-color: $brand-grey;


  .title {
    letter-spacing: 0;
    line-height: 4;
    margin: 7em 0 0 0;
  }
  img {
    position: absolute;
    transform: translateX(-50%)translateY(-100%);
    z-index: 99999;
  }
}