.m-game-grid {

    padding: $column-gap*2 0 $column-gap*2;
    max-width: 1915px;
    margin: auto;

    &.skeleton {
        grid-gap: 0.4rem;
    }

    &__container {
        border-top: 50px solid whitesmoke;
        padding: 20px 0 40px 0;
        min-height: 215px;

        > .container {
            min-height: 38px;

            a.has-text-primary {
                color:#572E89 !important;
                text-decoration: underline !important;
            }
        }

        &.container.is-fluid {
            margin-left: 0;
            margin-right: 0;
        }

        &:first-of-type {
            border-top: 0px solid;
        }
    }

    @include until($tablet) {

        padding: 0;

    }

    &-item {
        position: relative;
        border-radius: 8px;

        &:not(.featured-grid) {
            .m-game-grid-buttons {
                .button {
                    @include until($tablet) {
                        &:last-of-type {
                            margin-top: 0;
                            margin-bottom: 5px;
                        }

                        i {
                            display: none;
                        }
                    }
                    @include until(768px) {
                        font-size: 3vw;
                    }

                    @include from(768px) {
                        font-size: 1.4vw;
                    }

                    @include from(1130px) {
                        font-size: 1.1vw;
                    }

                    @include from(2000px) {
                        font-size: 1.3rem;
                    }

                    @media screen and (max-width: 768px) and (orientation: landscape) {
                        font-size: 1.4vw;
                    }

                    &-play-for-free {
                        @include until($desktop){
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        figure {
            background-color: white;
            padding-bottom: 69.5%;
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            height: 100%;
        }

        img {
            vertical-align: top;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
        }

        span img {
            vertical-align: top;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            transition: transform ease 250ms;
            transform: scale(1);
        }

        span {
            vertical-align: top;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            transition: transform ease 250ms;
            transform: scale(1);
        }

        i {
            margin-right: 10px;
        }

        figure img {
            transform: scale(1);
            transition: all ease 250ms !important;
        }

        &:hover {
            figure img {
                transform: scale(1.25);
                transition: all ease 250ms !important;
            }
        }
    }

    &-buttons {
        position: absolute;
        top: $column-gap;
        bottom: $column-gap;
        left: $column-gap;
        right: $column-gap;
        text-align: center;
        justify-content: center;
        align-content: space-around;
        flex-direction: column;
        overflow: hidden;
        transition: ease 500ms;
        border-radius: 8px;
        
        & > *:not(.favorited) {
            opacity: 0;
        }

        .slide & {
            top: 0;
            bottom:0;
            left:0;
            right:0;
            border-radius: 0;
        }
    
        &:hover,
        .on {
            background-color: rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(5px);
            transition: background-color ease 500ms, backdrop-filter ease 500ms;
            

            & > * {
                opacity: 1;
            }
            
            &:after {
                right: 100%;
                transition: right ease 500ms;
            }
        }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }


        .button {
            align-self: center;
            margin: $gap;
            transition: pointer-events ease 500ms;
            pointer-events: auto;
            text-transform: uppercase;

            @include until($fullhd) {
                padding: 0 10px;
            }

            &-play-for-real {
                border-radius: 50px;
                text-transform: uppercase !important;
                font-weight: 800;
                padding: 0px 1.5rem !important;
            }
            &-play-for-free {
                color: $teritary;
                text-decoration: none !important;
                text-transform: lowercase !important;
                position: absolute;
                margin-left: auto;
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto;
                bottom: 2px;
                width: 100%;
                font-weight:600;
                font-size: 13px;

                &:hover {
                    color: $light;
                    text-decoration: underline !important;
                }
            }
        }
    }
}



.m-game-grid-item__shadow
{
    box-shadow:0 1px 4px #887696, 0 0 40px #887696 inset;
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    bottom: 10px;
    z-index: -1;
}

.m-game-grid-item__shadow:before, .m-game-grid-item__shadow:after
{
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 10px 10px rgba(36, 1, 68, 0.5);
    top: 50%;
    bottom: -3px;
    left: 0;
    right: 0;
    border-radius: 100px / 10px;
}

.m-game-grid-item__label {
    background-color: $primary;
    color: white;
    text-align: center;
    position: absolute;
    top: 10px;
    right: -28px;
    width: 100px;
    box-shadow: 0 0 10px $primary;
    transform: rotate(45deg);
    font-weight: 700;
    text-transform: uppercase;
}

.m-game-grid-item__shadow:after
{
    right:10px;
    left:auto;
    transform:skew(8deg) rotate(3deg);
}

.m-game-grid-item__provider {
    padding: 0;
    & .m-tournament .m-tournament-main .m-tournament-table .m-tournament-table-body .m-game-section .m-game-section-container .slider .slide img,
    img {
        position: absolute;
        width: 40%;
        max-width: 80px;
        height: auto !important;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);

        &.BFGames {
            width: auto;
            max-height: 50px;

            @include until($desktop) {
                max-height: 30px;
            }
        }
    }
}

.favorite__buttons {
    position: absolute;
    top: .3rem;
    right: .3rem;
    background: transparent;
    border: none;
    z-index: 10;

    span {
        &.button-favorite {
            & svg {
                cursor: pointer;
                &:hover {
                    fill: darken(#ffdc16, 10%);
                    animation: heartbeat 1.5s ease;
                }
            }
        }

        &.button-favorite,
        &.button-favorited {
            position: initial;

            & svg {
                cursor: pointer;
                &:hover {
                    fill: darken(#fff, 10%);
                    animation: heartbeat 1.5s ease;
                }
            }
        }
    }

    @keyframes heartbeat {
                0% {
                    transform: scale(1.0);
                }
                15% {
                    transform: scale(1.1);
                }
                30% {
                    transform: scale(1.0);
                }
                50% {
                    transform: scale(1.1);
                }
                65% {
                    transform: scale(1.0);
                }
            }
}

.featured-grid {
  grid-row: span 2;
  grid-column: span 2;

  .button {
    @include from($desktop) {
        font-size: 1.5rem;
    }

      @include until($desktop) {
          font-size: 1.2rem;
      }

      @include until($tablet) {
        font-size: 1rem;
    }
  }
}

.vertical-grid {
    grid-row: span 2;
}

.parent-grid {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: row dense;
}

@media only screen and (max-width: 1130px) {
  .parent-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .parent-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .parent-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
