.m-loyalty-v2 {
  max-width: $container-width !important;

  &-intro-text {
    margin: auto;

    .button {
      margin: 50px auto 0;
      display: block;
      background-color: $dark;
    }
  }
}

.m-loyalty-section {
  margin-bottom: $gap * 10 !important;

  .m-loyalty-whammies-info {
    @include from($tablet) {
      margin-top: 90px;
    }

    .level-left {
      flex-shrink: 1;
    }
    p {
      min-width: 50px;
      overflow-wrap: break-word;
    }
  }
}

.m-loyalty-content-section {
  p {
    &:first-of-type {
      margin-top: 40px;
    }
  }
}

.m-loyalty-logo {
  margin: 0;
  display: block;
  @include until($desktop) {
    width: 300px;
  }
}

.m-loyalty-top-area {
  background-image: url(/images/loyalty/loyalty-scale-v2.png);
  background-position: top 50px right;
  background-repeat: no-repeat;
  background-size: 860px;

  .m-loyalty-logo {
    margin: 25px auto;
    @include from($tablet) {
      margin: 100px auto 50px;
    }
  }
  &.logged-in {
    background-image: none;

    a {
      color: white;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  @include from($tablet) {
    min-height: 820px;
  }
  @include until($desktop) {
    background-position: top 50px right -100px;
  }
  @include until($tablet) {
    background-size: 400px;
    background-position: top 50px right -50px;
  }
  @include until(580px) {
    background-image: none;
    .column {
      width: 100%;
    }
  }
}

.m-loyalty-scale {
  display: flex;
  margin-top: 50px;
  position: relative;
  text-align: center;
  @include from($tablet) {
    min-height: 840px;
  }
  @include from(580px) {
    display: none;
  }

  img {
    align-self: flex-start;
  }
}

.m-loyalty-spacemen {
  position: absolute;
  bottom: 0;
  right: 0;

  @include until($tablet) {
    right: -45px;
  }
  img {
    vertical-align: bottom;
    @include until($fullhd) {
      width: 400px;
      height: auto;
    }
    @include until($desktop) {
      width: 300px;
      height: auto;
    }
    @include until($tablet) {
      width: 200px;
      height: auto;
    }
  }
}

.m-loyalty-text-block {
  .content {
    @include from($fullhd) {
      font-size: 25px;
    }
  }
  .column {
    &.has-text:first-child {
      min-width: 260px;
      @include until($tablet) {
        p {
          padding-right: 60px;
        }
        @include until($tablet) {
          .button {
            margin-left: $gap;
            position: relative;
            z-index: 1;
          }
        }
      }
      @include from($tablet) {
        padding-left: 20px;
        padding-right: 270px;
      }
      @include from(710px) {
        padding-left: $gap * 2;
        padding-right: $gap * 2;
      }
    }
    &.has-image:last-child {
      position: relative;
      img {
        @include until(539px) {
          position: absolute;
          max-width: 200px;
          top: -90px;
          left: -80px;
        }
        @include from($tablet) {
          position: absolute;
          left: 61%;
          top: 0;
          width: 280px;
        }
        @include from($tablet) {
          width: auto;
          position: static;
        }
      }
    }
    &.has-image:first-child {
      position: relative;
      img {
        @include until($tablet) {
          position: absolute;
          top: -130px;
          min-width: 220px;
          left: -120px;
        }
        @include from($tablet) {
          position: absolute;
          right: 73%;
          top: 50%;
          width: 360px;
          transform: translateY(-50%);
        }
        @include from($tablet) {
          width: auto;
          position: static;
          transform: translateY(0);
        }
      }
    }
    &.has-rocket:last-child {
      img {
        @include until($tablet) {
          position: absolute;
          max-width: 260px;
          top: -100px;
          left: -80px;
          transform: rotate(-15deg);
        }
      }
    }
    &.has-text:last-child {
      @include until($tablet) {
        min-width: 210px;
        margin-left: -54px;
        @include until($tablet) {
          .button {
            margin-left: -$gap * 3;
          }
        }
      }
      @include from($tablet) {
        padding-left: 200px;
        padding-right: 20px;
      }
      @include from($tablet) {
        padding-left: $gap * 2;
      }
      @include from($widescreen) {
        padding-right: 200px;
      }
    }
  }
}

.m-loyalty-table-wrapper {
  @include until($desktop) {
    overflow-x: scroll;
  }
}

.m-loyalty-table {
  margin-top: 40px;
  tr,
  thead {
    td:first-child {
      width: 300px;
      @include until(1000px) {
        width: auto;
      }
    }
  }
  thead,
  th {
    td {
      color: white;
      padding: 10px;

      @include until(1000px) {
        text-align: center;
        min-width: 0;
      }
    }
  }

  td {
    padding: $gap * 2;
    vertical-align: middle;
    min-height: 70px;
    color: $content-text-color;
    font-weight: bold;
    min-width: 130px;
    border: 1px solid $dark !important;

    @include until($widescreen) {
      min-height: 91px;
    }
    @include from(1000px) {
      border: 1px solid $black !important;
    }
    @include until($tablet) {
      padding: 5px;
    }
  }

  img {
    vertical-align: middle;
    @include until(1000px) {
      width: 23px;
    }
  }

  tr:last-child {
    td {
      @include until(1000px) {
        border-bottom-width: 1px !important;
      }
    }
  }
}

.m-loyalty-terms {
  padding-left: $gap * 2;
  padding-right: $gap * 2;
}

.m-loyalty-nav {
  font-size: 18px;
  background-color: $black;

  a {
    color: $primary;
    font-weight: bold;
    padding-left: $gap * 2;
    padding-right: $gap * 2;
    &.is-active {
      color: $primary;
      background-color: #1f2b3a !important;
    }

    @include until($tablet) {
      display: block;
      font-size: 18px;
      text-align: center;
      padding-left: $gap;
      padding-right: $gap;
    }
  }

  &-footer {
    background-color: transparent;
    a {
      @include until($tablet) {
        font-size: 13px;
      }
    }
  }
}

.m-loyalty-shop {
  > .content {
    @include until($desktop) {
      padding: 0 $gap * 2;
    }
  }
  .columns {
    @include from($desktop) {
      margin-left: -1rem;
      margin-right: -1rem;
    }
    flex-wrap: wrap;
    .column {
      padding: 1rem;
      &.is-4-tablet {
        @include from($desktop) {
          flex: 1;
          width: auto;
        }
      }
    }
  }
}

.m-loyalty-shop-item {
  border: 0px solid;
  //padding: 0 $gap * 2 0 0;
  position: relative;
  background-color: transparent;
  width: 300px;
  height: 300px;
  text-align: center;
  cursor: pointer;
  img {
    vertical-align: middle;
    width: 100%;
  }
  @include until($tablet) {
    width: 200px;
    height: auto;
  }
}

.m-loyalty-shop-item-label {
  position: absolute;
  background: black;
  color: $primary;
  padding: 5px;
  font-weight: 800;
  min-width: 50%;
  font-size: 12px;
  @include from($widescreen) {
    font-size: 14px;
  }
  @include from($tablet) {
    width: 50%;
  }
  span {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.25;
    @include from($widescreen) {
      font-size: 32px;
    }
  }
}

.m-loyalty-modal {
  margin-bottom: 0 !important;
  //@include from($tablet) {
  padding: 0 $gap * 5 $gap * 3 $gap * 5;
  //}
  border: 1px solid $primary;
  border-radius: 4px;
  .modal-close {
    position: absolute;
    z-index: 11;
  }
  .modal-close:before,
  .modal-close:after {
    background-color: $primary;
  }
  .m-loyalty-shop-item {
    max-width: 300px;
    margin: auto auto $gap;
    pointer-events: none;
    height: auto;
    @include until($tablet) {
      max-width: 70%;
    }
    &-label {
      transform: translateX(50%) translateY(-50%);
      background-color: $primary;
      color: $black;
    }
  }
  &-title {
    max-width: 470px;
    margin: $gap * 2 auto auto;
  }
  .buttons {
    justify-content: center;
    margin-top: $gap * 3;
  }
  .button {
    @include from($tablet) {
      padding-left: $gap * 6;
      padding-right: $gap * 6;
      margin-left: $gap * 3;
      margin-right: $gap * 3;
    }
  }
  .m-loyalty-shop-item-label {
    margin-top: 0;
    transform: translateX(50%) translateY(-32px);
    span {
      font-size: 12px;
    }
    @include from($tablet) {
      span {
        font-size: 32px;
      }
      transform: translateX(50%) translateY(-50%);
    }
  }
}

.m-loyalty-modal-nav {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 150px;
  left: 25px;
  right: 25px;
  pointer-events: none;
  @include until($tablet) {
    top: 18%;
    left: 6px;
    right: 6px;
  }

  button {
    background: transparent;
    padding: 0;
    border: 0px solid;
    flex: 0 1 auto;
    width: 52px;
    cursor: pointer;
    pointer-events: all;
    @include until($tablet) {
      width: 32px;
    }
  }
  &-arrow-right {
    margin-left: auto;
  }
  &-arrow-left {
    margin-left: auto;
    img {
      transform: rotate(180deg);
    }
  }
}
#modal-loyalty {
  z-index: 9999;
  .modal-content {
    max-height: 100%;
  }
  .m-loyalty-modal {
    /* ... */
    overflow: auto;
  }
}

.m-loyalty-carousel-item {
  width: 100%;
  padding-top: 32px;
  @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
   img {
    width: 110px !important;
    margin-top: 50px;
   }
  }
}

.m-whammies-info {
  @include from($tablet) {
    width: 330px;
    .card {
      text-align: left;
    }
  }
  .card {
    border: 0px solid;
    text-align: center;
  }
}

.m-loyalty-whammies-info {
  border: 0px solid;
  margin: auto;
  margin-top: 50px;

  &.card {
    border-radius: 15px;
    height: auto;
    padding: 10px 20px;
  }

  .card-content {
    padding: 0;
    margin-bottom: 0;
    &:first-child {
      padding-bottom: 10px;
    }
  }
  .level-left {
    margin-bottom: 0;
  }

  h3 {
    @include until($tablet) {
      font-size: 13px !important;
    }
  }
}

.m-loyalty-shop-carousel {
  position: relative;
  @include until(1755px) {
    max-width: 90%;
  }
  @include until(1124px) {
    max-width: 85%;
  }
  margin: auto;
}

.m-loyalty-shop-carousel-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
}

.m-loyalty-shop-carousel-arrow {
  background: transparent;
  border: 0px solid;
  display: block;
  position: absolute;
  transform: translateY(-50%);
  outline: 0 solid;
  cursor: pointer;

  &:first-child {
    transform: rotate(180deg) translateY(50%);
    left: -75px;
  }
  &:last-child {
    right: -75px;
  }
  img {
    width: 85px;
  }

  @include until(750px) {
    img {
      width: 30px;
    }
    &:first-child {
      left: -45px;
    }
    &:last-child {
      right: -45px;
    }
  }
}

.m-loyalty-info-column {
  padding: 0 40px;
}

.m-loyalty-status-carousel {
  margin: auto;
  min-height: 434px;
  max-width: 100%;
  .m-loyalty-status {
    min-height: 60px;
    line-height: 60px;
    > img {
      margin-right: 10px;
      margin-left: 0;
    }
  }
  //visibility: hidden;
  &.flickity-enabled {
    @include from($desktop) {
      min-height: 468px;
    }
    visibility: visible;
  }
}

.m-loyalty-whammies-info {
  .m-loyalty-shop-carousel-arrow {
    &:first-child {
      left: -45px;
    }
    &:last-child {
      right: -45px;
    }
  }
}

.m-loyalty-status-carousel-container {
  position: relative;
  .m-loyalty-shop-carousel-arrows {
    max-width: 100%;
  }
}

.m-loyalty-status-carousel {
  padding: 5px 5px 20px;
}

.m-loyalty-status-carousel,
.m-loyalty-status-carousel-item {
  width: 100%;
  height: auto;
}

.m-loyalty-status-carousel-image {
  max-width: 512px;
  margin: auto;
  img {
    margin-bottom: 10px;
  }
}
