// =========================================================================
// Base - Defaults and Content
// =========================================================================

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: $family-sans-serif, sans-serif;
  font-style: normal;
  line-height: 1.6;
  min-height: 100%;
  background-color: white;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  padding: 0;
  margin: 0;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 0;
}

.content {
  h1 {
    font-size: 1.75em;
    margin-bottom: 0.5em !important;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.4em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:only-child) {
      margin-top: 2em;
      margin-bottom: 0.25em;
    }
    &:first-child {
      margin-top: 0;
    }
  }

  li {
    &::marker {
      color: $primary;
    }
  }

  color: $content-text-color;
  a {
    color: $content-text-color;
  }
  table thead th {
    color: $content-text-color;
  }

  blockquote {
    background-color: transparentize(white, 0.8);
  }
}

.typography {
  a {
    &:not(.button) {
      text-decoration: underline;
    }
  }
  p {

  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0 10px 22px;
    text-align: center;
  }
  strong {
    color: inherit;
  }
}

hr {
  background-color: $primary;
}

.m-page-width-wrapper .content.typography {
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:after {
      content: '';
      display: block;
      height: 3px;
      background-image: url('/images/dashed.svg');
      width: 100%;
      max-width: 640px;
      margin: 25px auto 0;
    }
  }
  h1 {
    padding-bottom: 5px;
  }
  p {
    font-size: 1.05em;
  }
}


