.m-landing-page {

  &-container {
    min-height: calc(100vh - 187px);
    background-color: $dark;
    text-align: center;
    color: white;
    padding-top: $gap*4;
    padding-bottom: $gap*4;
    background-size: cover;
    @include from($desktop) {
      background-attachment: fixed;
    }
    .columns {
      margin-top: $gap*3 !important;
      margin-bottom: $gap*3 !important;
    }
    .button {
      box-shadow: 0 0 10px rgba(0,0,0,0.75);
      text-decoration: none;
    }
  }

  &-header {
    object-fit: cover;
    min-height: 180px;
  }

  &-body {
    padding: $gap*2 0 $gap*4;
    background-color: $primary-dark;
    color: white;
    text-align: center;
    margin: $gap*2 auto 0;
    max-width: 1200px;
    strong {
      color: white;
    }
    a {
      color:white;
      text-decoration: underline;
    }
    ul {
      color: white;
      li::marker {
        color: white;
      }
    }
  }

  &-title {
    font-size: 60px;
    font-weight: 600;
    text-transform: uppercase;

    @include until($desktop) {
      font-size: 40px;
    }
    
    &-mid {
      font-size: 48px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: $gap*2;
      @include until($desktop) {
        font-size: 36px;
      }
      @include until($tablet) {
        font-size: 24px;
      }
    }
  }

  &-description {
    max-width: 480px;
    margin: auto auto $gap*4;
    font-size: 18px;
    font-weight: 600;

    @include until($desktop) {
      font-size: 16px;
    }
  }

  &-terms {
    @include from($desktop) {
      max-width: 520px;
      padding: 0;
    }
    margin: auto;
    margin-top: $gap*3 !important;
    padding: $gap*4;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  &-items {
    position: relative;
    .m-landing-page-description {
      margin-bottom: 20px;
      &:not(:first-child) {
        border-top: 1px solid #ccca;
      }
    }
  }

  &-plus {

    @include from($desktop) {
      position: absolute;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      top: 50%;
      width: 160px;
    }
  }

  
}

.m-offer-item {
  &-content {
    margin: $gap;
    background-color: black;
    padding: $gap*2 45px;
    color: white;
    border-radius: 20px;
    box-shadow:0 0 5px #8FE1FF;
    min-height: 350px;
    @include until($desktop) {
      display: inline-flex;
    }
    flex-direction: column;
    justify-content: space-between;
    @include from($desktop) {
      display: flex;;
      height: 100%;
    }
    p {
      margin: 0 !important;
      &:first-of-type {
        line-height: 1.2;
      }
    }
  }
  &-title {
    color: #8FE1FF !important;
    margin: 0 !important;
  }

  &:first-child {
    @include until($desktop) {
      &:after {
        content: '';
        display: block;
        background-image: url('/images/landing_pages/plus-symbol.png');
        background-size: contain;
        width: 100px;
        height: 100px;
        margin: auto;
      }
    }
  }
}

.m-offer-description {
  padding: $gap 0;
}