.m-winnerslist-cards-container {
  height: 850px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__box {
    width: 100%;
    @include until($desktop) {
      text-align: center;
      overflow: hidden;
    }
  }
  &__scroll-area {
    display: flex;
    width: 100%;
    max-width: 1400px;
    .flickity-slider {
      @include from(1370px) {
        transform: translateX(0) !important;
        cursor: auto;
      }
    }
    section {
      margin: 0 20px;

      @include until($desktop) {
        margin: 0 20px;
      }
      @include until($tablet) {
        margin: 0 10px;
      }
    }
  }

  @include until($desktop) {
    height: 100%;
    padding: 3rem 0;
    justify-content: center;
  }

  .bg {
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 80%
    );

    @include until($desktop) {
      height: 35px;
    }
  }

  &__video {
    height: 100%;
  }

  &__video-bg {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__box {
    display: flex;
    z-index: 1;
    align-items: center;
    height: 100%;
    column-gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    @include until(1350px) {
      padding-bottom: 30px;
    }

    @include until($tablet) {
      row-gap: 2rem;
    }

    .m-winnerslist-card {
      max-height: 450px !important;
      height: 450px;
      min-height: 450px;
      overflow: hidden;
      min-width: 420px;
      position: relative;
      margin-bottom: 20px;

      @include until($tablet) {
        width: 100%;
        min-width: 300px;
        max-width: 300px;
        margin-bottom: 50px;
        &__rankings-item {
          @include until($tablet) {
            grid-template-columns: 1fr 80px !important;

            &-user,
            &-score {
              text-align: right;
              justify-content: end;
            }
          }
        }
      }

      &__container {
        width: 100%;
        max-width: 340px;
        margin: 30px auto auto;
        @include until($tablet) {
          width: 100%;
          padding: 0 20px !important;

          .slider {
            width: 100% !important;
          }
        }
      }

      &__sub-title-container {
        white-space: pre-wrap;
        font-size: 1rem;

        @media screen and (max-width: 375px) {
          font-size: 0.8rem;
        }
      }
    }

    .m-winnerslist-card {
      @include until($desktop) {
        min-width: 310px;
        max-width: 310px;
      }

      @include until(375px) {
        width: 100%;
        min-width: 250px;
        max-width: 250px;
      }
    }

    .m-winnerslist-card {
      &__rankings {
        @include until($desktop) {
          .carousel-slider {
            height: 400px;
          }
        }
      }

      &__title-wrapper {
        justify-content: center;
        margin-bottom: 1rem;

        img {
          width: 40px;
          height: 45px;
          font-weight: bolder;
        }
      }

      &__title {
        width: 100%;
      }
    }

    .m-winnerslist-card {
      border-radius: 8px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 700px;

      &:nth-child(1) {
        background-image: url("/images/winners-widget/bg-01.jpg");
      }

      &:nth-child(2) {
        background-image: url("/images/winners-widget/bg-02.jpg");
      }

      &:nth-child(3) {
        background-image: url("/images/winners-widget/bg-03.jpg");
      }

      &__container {
        color: $primary;
        height: 100%;
        @include until($desktop) {
          padding: 0 20px;
        }
      }
      &__maintitle {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__maintitle_current {
        padding-bottom: 20px;
      }
      &__header {
        padding: 0.5rem;
      }
      &__header:nth-child(3) {
        margin-bottom: 10px;
      }
      &__rankings {
        margin: 32px auto 0;
        padding: 5px 0;
        color: white;
        .please-login {
          height: 250px;
          position: relative;
          p {
            margin: 0 auto;
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(-45deg);
          }
        }
      }

      &__rankings-player {
        .item-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          align-items: center;
          font-size: 0.9rem;
          box-sizing: border-box;
          position: relative;
          width: 100%;
          margin-bottom: 0.3rem;
          border-bottom: 1px solid #472478;
          padding-bottom: 0.6rem;
          padding-top: 0.1rem;
          & :first-child {
            text-align: left !important;
            padding-left: 10px;
            min-width: 120px;
          }
        }
      }
      &__rankingsplatform {
        max-height: 290px;
        height: 100%;
        margin-top: 10px;
        color: white;

        .carousel-root,
        .carousel-slider,
        .slider-wrapper {
          height: 100% !important;
        }

        .slider {
          width: 100% !important;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #000;
        }

        &::-webkit-scrollbar-thumb {
          background: black;
          border-radius: 5px;
          height: 50%;
        }

        &::-webkit-scrollbar-corner {
          background: rgba(0, 0, 0, 0.5);
        }

        &::-webkit-scrollbar-thumb:hover {
          background: darken(black, 10%);
        }

        &:not(.m-game-grid-item.column) {
          max-height: initial;
        }

        &-item {
          border-bottom: 1px solid #472478;
          display: flex;
          grid-template-columns: 1fr 80px;
          grid-template-rows: 38px;
          align-items: center;
          font-size: 0.9rem;
          box-sizing: border-box;
          position: relative;

          &__topwinners {
            width: 250px;
            margin: auto;

            figure {
              img {
                height: 55px !important;
              }
            }

            .m-winnerslist-card__rankingsplatform-item-score {
              width: 50%;
              display: block !important;
              text-align: right;
            }
          }

          @include until(500px) {
            grid-template-columns: 1fr 55px;
          }

          .item-container {
            display: flex;
            position: relative;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .m-winnerslist-card__topwinners {
              justify-content: center;
              figure img {
                height: 55px;
              }
              .m-winnerslist-card__rankingsplatform-item-score {
                margin-left: 0;
                text-align: right;
                min-width: 50%;
                display: block;
              }
              .m-winnerslist-card__rankingsplatform-item-image {
                min-width: 50%;
                img {
                  height: 55px;
                }
              }
            }
          }

          .item-container > div {
            height: 100%;
            width: 50%;
            display: flex;
            align-items: center;
          }

          &-score {
            margin-left: auto;
            .m-winnerslist-card__topwinners & {
              margin-left: 0;
            }
          }

          &-image {
            figure {
              padding: 0;
              margin: 6px 10px 5px;

              img {
                height: 40px;
                width: auto;
                border-radius: 4px;
              }
            }
          }
        }

        &-button {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;

          a,
          .m-winnerslist-card__sub-title {
            display: block;
            width: 100%;
            background-color: #503181aa;
            color: white;
            font-size: 1em;
            border: 0px solid;
            padding: 10px;
            &:hover {
              background-color: #503181ee;
            }
          }
        }
      }
    }
  }
}

.m-winnerslist-cards-container__scroll-area {
  width: 100%;

  .flickity-page-dots {
    display: block;
    .dot {
      background: white;
    }
    @include from(1390px) {
      display: none !important;
    }
  }
}

.m-timer-unit {
  opacity: 0.75;
}

.m-winnerslist-card__current .item-container {
  grid-template-columns: 1fr 2fr 2fr !important;
}

.m-winnerslist-card__rankings-item-user {
  padding-right: 10px;
}

.m-winnerslist-cards-container__box
  .m-winnerslist-card__rankingsplatform-item-score {
  justify-content: flex-end;
  padding-right: 10px;
}

.m-winnerslist-card__latest
  .m-winnerslist-cards-container__box
  .m-winnerslist-card__rankingsplatform-item-image
  figure {
  margin: 6px 10px 5px auto;
}

.m-winnerslist-card__latest
  .m-winnerslist-card__rankingsplatform-item
  .item-container
  > .m-winnerslist-card__rankingsplatform-item-image {
  width: 108px !important;
}
