.m-game-nav {
  line-height: $navbar-height;
  padding: 0 $gap;

  &.is-fluid {
    max-width: $container-width !important;
    margin-left: auto;
    margin-right: auto;

    @include until($tablet) {
      padding: 0 $gap/2 0 $gap/2;
    }

    > .level {
      @include until(375px) {
        justify-content: space-between;
      }
    }
  }

  &-parent.is-active {
    margin-bottom: 50px;
  }

  .navbar-item {
    color: $primary;
    transition: background-color ease 250ms;
    background: transparent;
    border: 0px solid;
    font-size: inherit;
    text-shadow: 0 0 5px white;
    cursor: pointer;
    @include until(1305px) {
      font-size: 13px;
      padding-left: $gap/2;
      padding-right: $gap/2;
    }
    @include until($desktop) {
      font-size: inherit;
    }
    &:hover {
      background-color: white;
      transition: background-color ease 250ms;
      color: $primary;

      @include until($desktop) {
        background-color: $light;
        color: $brand-dark;
      }
    }

    &.is-active {
      background-color: black;
      color: white;
    }
  }

  .level-right {
    align-items: center;
    line-height: $navbar-height;
  }

  &-button {
    .navbar-burger {
      pointer-events: none;
    }

    @include until($tablet) {
      padding: 0 0 0 5px;

      .navbar-burger {
        width: 2.5rem;
      }
    }
  }

  &-menu {
    border: 0px solid;
    border-radius: 0;

    > .navbar-item {
      @include until(1070px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    @include until($desktop) {
      display: none;
    }

    &.is-active {
      .navbar-item {
        width: 100%;
      }
      
      @include until($desktop) {
        .active {
          &.navbar-item.is-active:not(:focus):not(:hover) {
            background-color: $primary;
            color: $white;
          }
        }
        display: block;
        position: absolute;
        top: $navbar-height;
        width: 200px;
        background: white;
        box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
      }

      @include until($desktop) {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
      }
    }

    &-label {
      line-height: $navbar-height;
      color: $brand-dark;
    }

    .navbar-item {
      @include until($desktop) {
        padding: 0.375rem 1rem;
        &.is-active {
          background-color: $primary !important;
        }
      }
    }
  }

  &-search {
    line-height: $navbar-height;
    overflow: hidden;

    .m-game-nav-search-field {
      @include until($desktop) {
        position: absolute;
        right: 0;
        padding: $gap;
        top: $navbar-height;
        z-index: 2;
        visibility: hidden;
        &.is-active {
          top: $navbar-height;
          background: $primary;
          width: 100%;
          right: 0;
          transition: all ease 300ms;
          visibility: visible;
        }

        &.is-inactive {
          height: 0;
          width: 0;
          overflow: hidden;
          position: fixed;
        }
      }
    }

    &-button {
      line-height: $navbar-height;
      padding-top: 0;
      padding-bottom: 0;
      background-color: $brand-grey;
      border-color: $brand-dark;
      color: $brand-dark;
    }

    .input {
      color: $light;
    }

    .control.has-icons-left .input:focus ~ .icon {
      color: $light;
    }
  }

  &-secondary {
    &.navbar-item {
      &:hover {
        background-color: transparent;
      }

      .navbar-item {
        padding: 0.375rem 1rem;
        color: white;
        &.active {
          background-color: white;
          color: $brand-grey;
        }
      }
    }
    .m-game-nav-menu {
      &.is-active {
        .navbar-item {
          color: $brand-dark;
          &.is-active.active {
            color: $brand-dark;
          }

          &:hover {
            &.is-active.active {
              color: $brand-dark;
            }
          }
        }
      }

      @include until($desktop) {
        right: 0;
        left: auto;
      }
    }
  }
}

.m-top-nav-sub-area .box .button {
  vertical-align: baseline;
}

.m-game-search-field {
  background-color: white;
  &::placeholder {
    color: $primary;
  }
}

.m-game-nav-button {
  color: $brand-dark;
  &:hover {
    background: transparent;
  }
}

.m-top-nav-sub-area.m-top-nav {
  min-height: 55px;
  @include until($desktop) {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;

    .notification-active & {
      margin-top: 62px;
    }
  }
}

.m-top-nav-game-menu {
  @include from($desktop) {
    display: flex;
    margin-left: 75px;
  }
  @include from($widescreen) {
    margin-left: 166px;
  }


  .m-top-nav-item {
    &:first-of-type {
      @include from($desktop) {
        margin-left: 80px;
      }
      @include from($widescreen) {
        margin-left: 170px;
      }
    }
  }
}

.m-game-omni-logo {
  background-color: white;
  padding: 15px 5px 15px 10px;
  position: absolute;
  top: 0;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  @include until($widescreen) {
    img {
      width: 60px;
    }
  }
}

button.m-game-nav-button {
  background-color: transparent;
  border: 0px solid transparent;
  align-items: center;
  line-height: 0;
  outline: 0;
  outline: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: inherit;
  cursor: pointer;
}

.m-game-nav-secondary {
  .m-game-nav-menu.is-active {
    display: block;
    position: absolute;
    top: $navbar-height;
    width: 200px;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    .navbar-item {
      &:not(.active) {
        color: $primary;
      }

      &:hover {
        color: $brand-grey;
        background-color: $light;
      }
      &.is-active,
      &.active {
        background-color: $primary;
        color: white !important;
      }
    }
  }
}

.notification-service {
  padding: 0px !important;
}