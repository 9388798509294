.m-carousel {
    opacity: 0;
    &.m-carousel-short {
        height: 225px;
        min-height: 0;
        opacity: 1;
    }

    .container {
        margin: auto;

        @include until($widescreen) {
            background-position: center;
            background-size: cover;
            height: 100%;
        }
    }

    .carousel-cell {
        display: none;

        img {
            vertical-align: top;
        }

        @include until(1920px) {
            max-height: 500px;
        }

        @include until($desktop) {
            min-height: calc(100vh - 120px);
        }

        &:first-child {
            display: block;
            visibility: hidden;
        }

        .carousel-bg-mobile, .carousel-bg-desktop {
            width: 100%;
            height: 100%;
        }

        .carousel-bg-mobile {
            @include from($desktop) {
                display: none;
            }
        }

        .carousel-bg-desktop {
            @include until($desktop) {
                display: none;
            }
        }
    }

    // applied to parent when carousel js has loaded.
    &.flickity-enabled {
        opacity: 1;
        transition: opacity ease 500ms;
        transition-delay: 6s;

        @include until($desktop) {
            .notification-active & {
                margin-top: 132px;
              }
        }

        .flickity-slider {
            transform: translateX(0) !important;
        }

        &:before {
            display: none;
        }

        .carousel-cell {
            display: block;
            visibility: visible;
            left: 0 !important;
            transform: initial !important;

            @include until(1920px) {
                height: 500px;
                max-height: 500px;
            }

            @include until(600px) {
                height: calc(100vh - 120px);
            }

            .m-carousel-logged-in & {
                height: 260px;
            }
        }

        .container {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            display: flex;
        }

        .hero {
            max-width: $container-width;
            margin: auto;
            width: 100%;
        }
    }

    .flickity-page-dots {
        bottom: 25px;

        .dot {
            background-color: white;
            opacity: 1;

            &:only-child {
                display: none;
            }
            &.is-selected {
                background-color: $success;
            }
        }
    }





    .hero-tag {
        font-weight: 600;
        font-style: italic;
        text-shadow: -1px -1px 0 green, 1px -1px 0 green, -1px 1px 0 green, 1px 1px 0 green;
        color: #fff;
        text-align: center;
        width: 200px;
        transform: rotate(-10deg);
        font-size: 24px;
        position: absolute;
        left: 60px;
        line-height: 1;
        top: 17px;
    }

    .hero-title {
        font-size: 64px;
        font-weight: 700;
        color: white;
        margin-bottom: 20px;
        line-height: 100%;
        display: inline;
        text-shadow: 0 3px 0 #001c45;

        @include until($desktop) {
            font-size: 62px;
            font-size: 7vw;
            text-shadow: 0 3px 0 #001C45;

        }

        @include until($tablet) {
            font-size: 64px;
            font-size: 10vw;
        }

        &--secondary {
            font-size: 56px;
            margin-bottom: 0;

            @include until($widescreen) {
                font-size: 40px;
                font-size: 3.5vw;
                text-shadow: 0 3px 0 #001C45;
            }

            @include until($tablet) {
                font-size: 4.5vw;
            }
        }


    }

    .hero-sub-title {
        font-size: 32px;
        font-weight: 700;
        margin-top: 20px !important;
        padding: 0;
        display: inline;
        text-shadow: 0 3px 0 #001c45;
        word-spacing: 2px;

        @include until($widescreen) {
            font-size: 25px;
            font-size: 5vw;
            text-shadow: 0 3px 0 #001C45;
        }

        @include until($tablet) {
            font-size: 6vw;
        }
    }

    .hero-body {
        padding-left: $gap*4;
        max-width: 480px;

        @include until($desktop) {
            padding-left: 1.5rem;
            max-width: none;
        }

        > * {
            margin-bottom: 0;
        }


    }

    .hero-title-wrapper {
        line-height: 42px;
        margin-bottom: 11px;
        letter-spacing: -3px;
        word-spacing: -5px;
        text-transform:uppercase;
        @include until($tablet) {
            line-height: 34px;
            word-spacing: initial;
        }
    }

    .carousel-cell {
        background-size: contain;
        background-position: bottom center;
        width: 100%; /* full width */
        height: auto;



        &:before {
            content: '';
            display: block;
            padding-bottom: 25%;
        }

        .carousel-bg-image {
            background-image: url(/images/bg-carousel-default.jpg);
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            background-size: cover;
            background-position: center;
        }

        &.is-vip {
          .hero-body {
            @include from($desktop) {
              max-width: 530px;
            }
          }
          .carousel-bg-image {
            img {
              @include from($desktop) {
                right: auto;
                left: 0;
              }
              @include until($desktop) {
                display: none;
              }
            }
          }
        }

        @include from(1920px) {
            &:after {
                content: '';
                display: block;
            }
        }

        img,
        video {
            @include until(1920px) {
                width: 1920px;
                height: 500px;
                position: absolute;
                right: 0;
                max-width: none;
            }

           
         
            position: absolute;
            width: 100%;
            display: block;
            top: 0;
            right: 0;
        }

       

        video {
            object-fit: cover;

            @include until(1920px) {
                width: 100%;
                height: 100%;
                position: absolute;
                /* right: 0; */
                max-width: none;
                right: 50%;
                transform: translateX(50%);
            }

            @include from($widescreen) {
                right: 0;
                transform: translateX(0);
            }
        }
    }

    .hero-title-section {

        &__block {
            display: inline-block;
        }

        &__title {

            font-size: 2.3vw;

            @include from(1905px) {
                font-size: 42px;
            }

            @include until($desktop) {
                font-size: 1.5vw;
            }

            @include until($tablet) {
                font-size: 3.5vw;
            }

        }

    }

    .flickity-viewport {
        transition: height 0 ease;
    }
}


.m-carousel-logged-in {
    opacity: 1;
    min-height: 340px;
    background: linear-gradient(#404098, #522986);
    height: auto;
    
    @include until($desktop) {
        .notification-active & {
            margin-top: 145px;
          }
    }

     // applied to parent when carousel js has loaded.
    &.flickity-enabled {
         .flickity-viewport {
            min-height: 260px;
        }
        .carousel-cell {
            height: 260px;
        }
    }

}

.m-carousel-accounts {
    height: 225px;
    min-height: 0;
    background-size: cover;
    background-position: center;
    opacity: 1;
    background-color: $brand-dark;
    .container {
        background: transparent;
    }
}

.m-carousel-short {
    height: 225px;
    min-height: 0;
    background-size: cover;
    background-position: center;
    opacity: 1;
    background-color: $brand-dark;
    // background-image: url('../images/support/support-email-hero.jpg');

    .container {
        background: transparent !important;
    }
}
.m-carousel-banking {
    // background-image: url('../images/banking-hero.jpg');
}

.carousel-cell {
    background-color: $brand-dark;
}

.carousel-video {
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 220px;
}