@keyframes fadeloader {
  0% {
    background-color: #ffffff30;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #ffffff30;
  }
}

.m-tournament {
  max-width: 2400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .m-tournament-layout {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    @include until($desktop) {
      //margin: 2rem 0;
    }
  }

  .m-tournament-hero {
    position: relative;

    & video {
      border-radius: 8px;
    }

    & img {
      border-radius: 8px;
    }

    .m-tournaments-hero-title {
      position: absolute;
      top: 3rem;
      color: white;

      @media screen and (max-width: $desktop) {
        display: block;
        width: 100%;
        text-align: center;
        top: 0;
      }

      @media screen and (min-width: $desktop) {
        margin-left: -10px;
        padding: 10px 50px;
        display: inline-block;
      }
    }

    @include until($tablet) {
      order: 2;
    }
  }

  .m-tournament-nav {
    .m-tournament-nav-button {
      line-height: 3rem;
      border: 0px solid;
      padding: 0 0 0 15px;
      border-radius: 5px;
      margin: 10px;
      color: white;
      background: linear-gradient(to right, #552c88, #00a9b4);
      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }

    .navbar-item {
      color: $primary;
      border: 0px solid;
      font-size: inherit;
      font-weight: inherit;
      outline: 0;
      cursor: pointer;

      @include until($desktop) {
        width: 100%;
      }
    }

    @include until($desktop) {
      order: 1;
      padding: 2rem 0;

      .m-tournament-nav-menu {
        display: none;
      }

      .m-tournament-nav-menu.is-active {
        transform: translateX(-50%);
        left: 50%;
        top: 3.9rem;
        background-color: $brand-grey;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        text-align: center;

        a {
          display: block;
          color: $white;
          &.is-active,
          &:hover {
            background-color: $white;
            color: $brand-grey;
          }
        }
      }
      .level-left {
        margin: auto;
      }
    }

    @include from($desktop) {
      font-weight: bold;
      margin-bottom: $gap * 4;

      .navbar-item {
        color: $primary;
        &.is-active {
          background: transparent !important;
          color: $primary;
          text-decoration: underline;
        }
        &:hover,
        &:focus {
          color: $primary;
          text-decoration: underline;
          background: transparent !important;
        }
      }
    }
  }

  .m-tournament-main {
    padding: 0 1rem;

    .m-tournament-sub-header {
      position: relative;

      .m-tournaments-main-subtitle {
        position: absolute;
        top: 15%;
        left: 0;
        padding: 0 15px;
        display: inline-block;
        z-index: 1;
        color: $white;

        @media screen and (max-width: $desktop) {
          left: 1rem;
          text-align: center;
        }

        @media screen and (min-width: $desktop) {
          margin-left: -10px;
          display: inline-block;
          width: initial;
        }
      }
    }

    .content {
      margin-bottom: 3rem;
    }

    .m-tournament-table {
      background: linear-gradient(to right, #552c88, #00a9b4);
      color: $white !important;
      height: calc(100% - 2rem);
      border-radius: 8px;
      margin: 1rem;
      display: flex;
      flex-direction: column;

      .m-tournament-table-header {

        .m-tournament-header-title {
          padding: 0.5rem 1rem;
          margin: 0;

          & > div {
            margin: 0;
            padding: 0.3rem 0;
            text-align: center;
          }

          .level-right {
            @include until($desktop) {
              width: 100%;
            }
          }

          .title,
          .subtitle {
            margin: 0;
            align-items: center;
            color: $white;
            width: 100%;
          }

          i {
            margin-left: 0.5rem;
            color: $white;
          }

          &.is-open {
            i {
              transform: rotate(180deg);
            }
          }

          @include until($desktop) {
            flex-direction: column;
            justify-content: center;
          }
        }

        .m-tournament-header-dates {
          background-color: #313F48;
          padding: 1rem;

          & > div {
            .table-container {
              width: 100%;
            }

            table tr {
              color: $white;
              justify-content: space-between;

              td {
                padding: 0.2rem 0.5rem;
                width: 100%;

                &.m-end-date {
                  background-color: #000 !important;
                  color: #fff !important;
                  border-radius: 10px;
                  padding: 0.1rem 0.5rem;
                  align-items: center;
                  text-align: right;
                }

                &:first-child {
                  text-align: left;
                }
                
                &.time-opacity{
                  opacity: 0;
                }
              }
            }
          }

          .level-left {
            .table {
              width: 100%;

              tr {
                justify-content: space-between;
                td {
                  .m-end-date {
                    background-color: #000;
                    border-radius: 10px;
                    color: #fff;
                    padding: 0.2rem 0.6rem;
                    display: block;

                    @include from($tablet) {
                      width: 70%;
                    }
                  }
                }
              }
            }

            @include from(1200px) {
              width: 50%;
            }
          }

          .level-right {
            margin-top: 0;


            div {
              align-items: flex-end;
            }

            @include until($desktop) {
              padding-top: 0.5rem;

              p {
                text-align: center;
              }
            }

            @include from(1200px) {
              width: 50%;
            }
          }

          @include until($tablet) {
            padding: 0.5rem;
          }

          @include until(1200px) {
            display: block !important;

          }
        }
      }

      .m-tournament-table-body {
        border: none;
        outline: none;
        transition: 0.4s;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .columns {
          flex-direction: column;

          .column {
            margin: 0 1.5rem;

            @include from($fullhd) {
              flex-shrink: 0;
              flex-grow: 1;
              width: 50%;
            }

            @include until($tablet) {
              margin: auto 0;

              &:first-child {
                margin-bottom: 1rem;
              }
            }

            &:nth-of-type(2) {
              & > div:nth-of-type(2) {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem;
                background: #6F879A;
                column-gap: 1rem;
                margin-top: 1rem;

                span {
                  display: inline-block;
                  width: 25px;
                  height: 25px;
                  border: 2px solid $steps-active-color;
                  border-radius: 3px;
                  position: relative;

                  i {
                    position: absolute;
                    left: 7px;
                    color: $light-green;
                    transform: scale(1.6);
                  }
                }
                p {
                  color: white;
                  font-weight: bold;
                  text-align: center;

                  @include until($tablet) {
                    font-size: 0.8rem;
                  }
                }
              }
              table.current-player {
                background: #6F879A;
                color: $white;
                font-weight: bold;
                margin: 0.3rem  0 1rem;

                td:last-child {
                  text-align: right;
                }
              }

              & > .button {
                margin: 1.5rem auto .5rem auto;
                display: block;
              }
            }
          }
          @include until($tablet) {
            padding: 0 1rem;
          }

          @include from(1200px) {
            flex-direction: row;
          }
        }

        .m-ranking-table-title {
          justify-content: space-between;

          h3 {
            color: #fff;
            width: 100%;
          }

          @include until($tablet) {
            padding: 0 1rem;
          }
        }

        .m-ranking-table-body {
          max-height: 275px;
          height: 100%;
          overflow-y: auto;
          margin-bottom:1rem;
          position: relative;

          .m-ranking-table-wrapper {
            overflow-y: auto;

            .table {
              width: 100%;
              font-size: 0.8rem;
              color:white;

              tbody {
                position: relative;

                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%) rotate(345deg);
                  text-transform: uppercase;
                  color: #bcbcbc;
                  font-size: 1.3rem;
                  text-align: center;
                  width: 100%;

                  h3 {
                    font-weight: 600;
                  }

                  @include until($tablet) {
                    font-size: 1rem;
                    width: 70%;
                  }

                  @include from($desktop) {
                    width: 70%;
                    font-size: 1.2rem;
                  }
                }

                tr {
                  &:nth-child(odd) {
                    background-color: #6F879A;
                  }
                  .m-user-prize {
                    display:block;
                    margin: 0 1rem;
                    white-space: nowrap;
                  }
                  .m-user-name {
                    width:100%;
                  }
                  .m-user-score,

                  .m-user-rank {
                    width: 30px;
                  }
                  .m-user-prize,
                  .m-user-rank {
                    font-weight: 600;
                  }
                  &.is-active {
                    color: $white;
                    font-weight: bold;
                  }
                }
              }

              @include from($tablet) {
                font-size: 1rem;
              }

              .m-user-rank {
                font-weight: 600;
              }


              td {
                padding: 5px;
              }


            }

            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }

            &::-webkit-scrollbar-track {
              background: #000;
            }

            &::-webkit-scrollbar-thumb {
              background: $white;
              border-radius: 5px;
              height: 50%;
            }

            &::-webkit-scrollbar-corner {
              background: rgba(0,0,0,0.5);
            }

            &::-webkit-scrollbar-thumb:hover {
              background: darken($primary, 10%);
            }
          }

          /* width */
          &::-webkit-scrollbar {
            width: 7px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: $brand-grey;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $primary;
            border-radius: 5px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: darken($primary, 10%);
          }
        }

        .m-game-section-title {
          h3 {
            color: #fff;
            width: 100%;
          }
        }

        .m-game-section {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          .m-game-grid-buttons {
            border-radius: 8px;
            left: 0.3rem;
            right: 0.3rem;
          }
          &.one-game-only {
            .m-game-section-container {
              width: 356px;
              
              .m-game-grid-buttons {
                top: 0;
                bottom: 0;
                left: 0.2rem;
                right: 0.2rem;
              }
            }

            .m-game-navigation-left,
            .m-game-navigation-right {
              display: none;
            }
          }

          .m-game-section-container {
            overflow: hidden;
            width: 100%;
            position: relative;
            &.isLoading:before {
              content: '';
              display: block;
              position: absolute;
              top: 1rem;
              left: 3.2rem;
              bottom: 1rem;
              right: 3.2rem;
              animation: fadeloader 1s infinite;
            }
            .slider {
              height: 100%;
              &.animated {
                width: auto !important;
              }
            }

            .slide {
              padding-left: 0.4rem;
              padding-right: 0.4rem;
            }


            @include from(1200px) {
              width: 450px;
            }

    
          }

          .m-game-navigation-left,
          .m-game-navigation-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 60px;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }

            @include until($tablet) {
              width: 60px;
            }
          }

          .m-game-navigation-left {
            left: 0rem;

            @include until($tablet) {
              left: -1.5rem;
            }

            @include from($tablet) {
              left: -2rem;
            }
          }

          .m-game-navigation-right {
            right: 0rem;

            @include until($tablet) {
              right: -1.5rem;
            }

            @include from($tablet) {
              right: -2rem;
            }
          }

          @include until($desktop) {
            width: 95%;
          }

          @include from($desktop) {
            width: 100%;
            max-width: 470px !important;
          }

          aspect-ratio: 556/284;

    

        }

        @include until(1200px) {
          padding-bottom: 2rem;
        }

        .m-game-grid-item__shadow {
          display: none;
        }
      }

      @include until($tablet) {
        padding: 0;
        .m-tournament-table-header {
          text-align: center;
          .m-tournament-header-title {

            .level-right {
              justify-content: center;
            }
          }

          .m-tournament-header-dates {
            display: block;
          }
        }

        .title,
        i {
          margin: 0;
        }
        .title {
          margin-right: 0.5rem;
        }
      }

      &.is-closed {
        .m-tournament-table-body {
          padding: 0 18px;
          background-color: $brand-grey;
          display: none;
          overflow: hidden;
        }

        a {
          color: #fff;
        }

        .m-tournament-table-header {
          color: #fff;

          .m-tournament-header-title {
            i {
              transform: rotate(180deg);
            }
          }
        }

        .m-tournament-header-dates {
          background: #313F48;
          color: #fff;

          .level-left {
            table {
              tr {
                color: #fff;
              }
            }
          }
        }
      }
    }

    @include until($desktop) {
      order: 3;
      .content {
        margin: 2rem 0;
        padding: 0;
      }
    }

    .m-tournament-loader {
      margin-bottom: 1rem;

      &.wrapper {
        background: #1d2326;
        width: 100%;
        padding: 1rem;

        .item {
          background: transparent;
          border-radius: 3px;
          padding: 12px 0;
          margin: 0 auto;
          width: 100%;

          &.header {
            padding-bottom: 0;
            > div:first-child {
              height: 60px;
            }

            &__body {
              height: 130px;
              animation-delay: 1s;
            }
          }

          &.body {
            padding: 0;

            &__title {
              height: 70px;
            }
            &__wrapper {
              display: flex;
              justify-content: space-between;
              height: 250px;

              .body__left,
              .body__right {
                height: 100%;
                width: 48%;
              }
            }
          }
        }

        @keyframes placeHolderShimmer{
          0%{
            background-position: -850px 0
          }
          100%{
            background-position: 850px 0
          }
        }

        .animated-background {
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: linear;
          background: #f6f7f8;
          background: linear-gradient(to right, #191F20 8%, #2b2b2b 18%, #191F20 33%);
          background-size: 800px 104px;
          height: 100%;
          position: relative;
          border-radius: 5px;
        }
      }
    }
  }

  .m-tournament-sidebar {
    .columns {
      flex-direction: column;
      gap: 1rem;
      margin-left: 1rem;
      padding-bottom: 20px;

      .column {
        border: 1px solid $brand-grey;
        width: 100%;
      }
    }
    @include until($tablet) {
      display: none;
    }
  }
}

.m-tournament-header-image {
  position: relative;
}

.m-tournament-header-image > img {
  vertical-align: middle;
  width: 100%;
}

.is-closed .m-tournament-header-image {
  display: none;
}

.m-tournament-header-live {
  position: absolute;
  top: 20px;
  right: 0;
  background-color: #000;
  color: #00ff00;
  text-transform: uppercase;
  padding: 0.25rem 0.5rem;
  border-radius: 4px 0 0 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  span {
    display: block;
    width: 10px;
    height: 10px;
    background-color: #00ff00;
    border-radius: 10px;
    margin-left: 0.5rem;

  }
}

.m-tab-buttons {
  display: flex;
  justify-content: center;

  button {
    background-color: transparent;
    border: 0px solid;
    color: $white;
    font-weight: bold;
    font-size: 1rem;
    @include until($fullhd) {
      padding: 0.5rem;
    }
    @include until($widescreen) {
      font-size: 0.8rem;
      padding: 0.5rem;
    }
    @include until($desktop) {
      font-size: 0.8rem;
      padding: 0.5rem;
    }
    @include until($tablet) {
      padding: 0.5rem;
    }
    @include until(640px) {
      padding: 0.25rem 0;
    }
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid transparent;

    &:only-child {
      pointer-events: none;
      &:hover {
        background-color: transparent;
      }
    }

    span {
      margin-left: 0.5rem;
      display: inline-block;
    }
    &.is-active {
      border-bottom: 1px solid $white;
      color: $white;

        &:hover {
          img {
            filter: invert(0);
          }
        }

    }

    &:hover {
      background-color: rgba(0,0,0,0.5);
      color: white;
      
    }
  }
}

.m-tab-content {
  max-width: 508px;
  margin: auto;
  width: 100%;
}

.m-tournament-widgets {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1rem !important;
  max-width: $widescreen;
  @include from($widescreen) {
    margin-left: auto;
    margin-right: auto;
  }
}

.m-tournament-table-body-content {
  display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.m-carousel-game-thumb {
  max-width: 350px;
  margin: auto;
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
}

.m-tournament-header-description {
  min-height: 5rem;
}

.m-tournament-detail-content {
  max-height: 18rem;
  overflow: auto;
  &.content {
    strong {
      color: #fff;
    }
  }
  &.m-tournament-has-ended {
    max-height: 22rem;
  }
  .m-tournament .m-tournament-main & {
    margin: 1rem;
    padding-right: 2rem;
  }
   /* width */
   &::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $brand-grey;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken($primary, 10%);
  }
}

.m-tournament-nav-menu {
  .m-top-nav-item.active {
    text-decoration: underline;
  }

  .m-top-nav-item.menu1:hover {
    text-decoration: underline !important;
  }

  .m-top-nav-item.menu1:has(~ .active) {
    text-decoration: none;
  }
}