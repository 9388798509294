.m-vip-icons {
    margin: $gap 0;

    .column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
        @include until($desktop) {
            margin: $gap*2 0;
        }

        p {
            margin: $gap 0;
            font-weight: 600;
        }
        @include until($tablet) {
            display: flex;
            flex-direction: row;
        }

        img {
            max-height: 48px;
        }
    }
}

.m-vip-benefit {
    width: 100%;
}

.m-vip-section {
    @include until($tablet) {
        padding: 3rem 1.5rem !important;
    }
    padding: 3rem 0rem !important;
}

.m-vip-calendar {
  padding-top: 10px;
  padding-bottom: 10px;
    .column {
        position: relative;
    }
}

.m-vip-carousel-title {

    &.hero-title {
      color: $primary;
    }

    .m-carousel & {
        margin-bottom: 20px;
        line-height: 1.5;
    }
}

.m-vip-carousel-sub-title {

    .m-carousel & {
        background-color: transparent;
        display: inline-block;
        margin-bottom: $gap*2 !important;
        color: white;

        @include from($tablet) {
            max-width: 50%;
        }
        @include from($desktop) {
            max-width: 490px;
        }
    }
}