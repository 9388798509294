.notification-box {
    background-color: lightgray;
    color: #552c88;
    padding: 10px;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include until($desktop) {
        font-size: 12px;
        position: relative;
      }
  }
  
  .notification-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .notification-title {
    justify-content: center;
    font-size: 1.2rem;

    @include until($desktop) {
        font-size: 0.9rem;
        position: relative;
      }
  }
  
  .notification-body {
    width: 100%;
  }