.m-support-item {
  margin-bottom: $gap * 4;
  display: flex;
  &-image {
    height: 200px;
    background-repeat: none;
    background-size: cover;
    background-position: top right -70px;
    padding: $gap * 4 $gap * 4 $gap * 2;
    margin: $gap;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 8px;

    > * {
      padding: 0px !important;
    }

    @include until($widescreen) {
      background-position: top right;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    h2 {
      letter-spacing: -3px;
      word-spacing: -2px;
    }
  }

  &-text {
    padding: $gap;
    margin: $gap 0;
    display: flex;
    flex-direction: column;
  }

  &-button {
    margin-top: auto;
  }

  @include until($fullhd) {
    .m-title-font {
      @include text-stroke(2, $primary);
    }
  }
}

.m-social {
  margin-top: $gap * 3;
  position: relative;
  line-height: 100px;

  .column {
    text-align: center;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 3px;
      left: 10%;
      right: 10%;
      background-color: $primary;
      position: absolute;
      top: calc(112px / 2);
      left: 0;
      right: 0;
      z-index: 0;

      @include until($tablet) {
        top: calc(64px / 2);
      }
    }

    @include until($tablet) {
      .social-image {
        max-height: calc(112px / 2);
      }
    }
  }

  i {
    font-size: 7rem;
    background: $white;

    @include until($desktop) {
      font-size: 4rem;
    }

    &.icon-facebook {
      color: $facebook;
      transition: color ease 100ms;

      &:hover {
        color: transparentize($facebook, 0.25);
        transition: color ease 100ms;
      }
    }

    &.icon-twitter {
      color: $twitter;
      transition: color ease 100ms;

      &:hover {
        color: transparentize($twitter, 0.25);
        transition: color ease 100ms;
      }
    }
    &.icon-youtube {
      color: $youtube;

      &:hover {
        color: transparentize($youtube, 0.25);
        transition: color ease 100ms;
      }
    }
  }
}

.social-image {
  position: relative;
}

.m-bucket-page-items {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
