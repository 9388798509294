// =========================================================================
// Layout - Sections of Page
// =========================================================================

// =========================================================================
// Site Header
// =========================================================================

.header.is-fluid.container {
    max-width: $container-width !important;
    margin: auto;
    padding: 15px $gap;

    @include until($desktop) {
        box-shadow: 0 0 30px rgba(0,0,0,0.4);
        position: relative;
        top: 0;
        z-index: 32;
        width: 100%;
        background-color: $white;
        padding: 0;
    }

    @include until($tablet) {
        padding-right: 0;
    }

    @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
      position: static;
    }
}

.m-site-header {
    background-color: white;
    position: relative;

    @include until($desktop) {
        position: fixed;
        top: 0;
        z-index: 32;
        width: 100%;
        padding: 0px;
    }
}

.m-header-account-sign-in {
    .field {
        margin-top: 0 !important;
    }
    .help {
        position: absolute;
        margin-top: 0;
    }

    .button.is-dark-green {
        @extend .is-dark-green;
    }
    #logout {
        margin-right: $gap;
    }
}

.m-top-nav-brand.rtp {
    justify-content: flex-start;
    height: 83px;

    @include until($desktop) {
        padding-left:1rem;
    }
}

.m-account-menu-item {
    color: $white;
    svg {
        width: 1.3rem;
        vertical-align: top;
        margin-right: 0.5rem;
    }
    path {
        fill: white;
    }
    &:hover {
        path {
            fill: black;
        }
    }

    .icon-sign-out {
        margin-top: 0.15rem;
        transform: rotate(180deg);
    }



}

.m-balance .icon {
    font-size: 1.25rem;
}

.m-account-dropdown-menu {
    .dropdown {
        &.is-active {
            .button {
                border-radius: 8px 8px 0 0;
                border-bottom: 1px solid white;
            }
        }
        .dropdown-trigger > button {
            padding: 0 1.5rem;
            background-color: $dark;
            border-color: $dark;
            color: white;
            &:hover {
                background-color: rgba(255,255,255,0.2);
                color: black;
            }
        }
    }
    .dropdown-menu {
        width: 100%;
        min-width: 0;
        padding: 0;
        color: $dark;
    }
    .dropdown-content {
        padding: 0;
        border: 1px solid $dark;
        border-top: 0 solid;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
    }
    .dropdown-item {
        border-bottom: 1px solid white;
        background-color: $dark;
        color: white;
        &:first-child {
            border-top: 1px solid white;
        }
        &:last-child {
            border-bottom: 0px solid;
            background-color: $dark;
            color: white;
        }
    }
    button.dropdown-item {
        background-color: $dark;
        color: white;
        border: 0;
        cursor: pointer;
        &:hover {
            background-color: white;
            color: black;
        }
    }
}

.m-header-balance-wrapper {
    display: block;
    position: relative;
    top: 0.58rem;
    white-space: nowrap;

    .buttons {
        margin-bottom: 0;
        display: block;
    }

    .button {
        margin-bottom: 0;
        width: 100%;
    }
}

.m-header-balance {
    font-size: 0.75rem;
    background-color: transparent;
    border: 0px solid;
    cursor: pointer;
    display: block;
    margin: auto;
    position: relative;
}
